
import React from 'react';
import { Well } from "../wells/models";
import { Marker, Popup } from 'react-leaflet';
//import L from "leaflet";
import MarkerClusterGroup from "./MarkerCluster";
//import MarkerClusterGroup from 'react-leaflet-markercluster/src/react-leaflet-markercluster';
//import { MapConfigs } from './MapConfigs';
import { compose } from 'redux';
import { connect } from "react-redux";
//import { render } from 'react-dom';
import { withRouter } from "react-router-dom"; // Link

import { withStyles } from "@material-ui/core/styles";
import * as authActions from "../auth/actions";
import * as navActions from "../common/actions";
import Button from "@material-ui/core/Button";
//const { BaseLayer, Overlay } = LayersControl;

require('react-leaflet-markercluster/dist/styles.min.css');

const allWells = Well.selectAll();

const styles = {};

class MapLayers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded:false,
            items: []
        };
    }
    /*
    componentDidMount() {
        const { authState } = this.props;
        fetch("/wells.json", {
                headers: {
                    "Authorization":"Token " + authState.user.auth_token
                },
                credentials: "same-origin"
            })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.items[0].detail === undefined) {
                        this.setState({
                            isLoaded:true,
                            items: result.items
                        });
                    } else {
                        this.setState({
                            isLoaded:false,
                            items: result.items
                        });
                    }
                },
                (error) => {
                    this.setState({
                        isLoaded:true,
                        error
                    });
                }
            );
    }
    */
    mapPosition(item) {
      if (item.location === undefined) {
        return [36.64, -119.81];
      } else if (item.location.coordinates === undefined){
        return [36.64, -119.81];
      } else {
        return [item.location.coordinates[1], item.location.coordinates[0]];
      }
      /*
      if (item.lat == undefined || item.lng == undefined)
        position = 
      else 
        position = [item.lat, item.lng];
      return position;
      */
    }
  handleNav(item, history) {
      this.props.well_selected(item.well_id, item.id);
      this.props.navSelectPage("welldetail_" + item.id);
        this.props.history.push("/welldetail/" + item.id);
  }
    
  render() {
    const { error } = this.state; //, isLoaded
    const { authState, wells } = this.props; //navigation
    const position = [36.64, -119.81];
    // this.mapPosition(item)
    if (!authState) return <MarkerClusterGroup><Marker position={position}></Marker></MarkerClusterGroup>;
    if (error) {
        return <MarkerClusterGroup><Marker position={position}></Marker></MarkerClusterGroup>;
    } else {
      return (
          <MarkerClusterGroup>
            {wells.map(item => (
              <Marker position={this.mapPosition(item)}>
                <Popup><Button
                        title={item.well_id}
                        to={"/welldetail/" + item.id}
                        onClick={() => this.handleNav(item, this.props.history)}
                    >Edit: {item.well_id}</Button></Popup>
              </Marker>
            ))}
          </MarkerClusterGroup>
      )
    }
  }
}

MapLayers = connect(
  state => ({
    authState: state.auth,
    wells: allWells(state),
  }),
  {
    ...Well.actions,
    ...authActions,
    ...navActions
  }
)(MapLayers);

export default compose(withStyles(styles), withRouter)(MapLayers);
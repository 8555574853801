import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
//import Grid from "material-ui/Grid";

import { withStyles } from "@material-ui/core";
//import { Redirect } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import Navigation from "./Navigation";
//import NotReady from "./NotReady";

const styles = theme => ({
    appContainer: {
        // The parent container of the header, sidebar, content, and footer
        //paddingTop: 80, // 64 = Height of Header; + 16 padding
        paddingTop: 50,
        height: "calc(100% - 50px)" // Height 100% - padding; This is set so the dashboard map can be 100%
    },
    contentContainer: {
        // The sibling container to the header, sidebar, and footer that contains the main content
        transition: theme.transitions.create("padding", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        }),
        "& > div:first-child, & > form:first-child": {
            // Set the padding to the footer on the first child for the content container
            paddingBottom: 60 // 32 = Height of Footer; + 8 padding + 20 extra
        },
        // The spacing on Material UI's grid works great when spacing between grid items, however the container itself is not spaced
        "& > div:first-child, & > form div:first-child": {
            width: "calc(100% - 40px)",
            marginTop: -2, // Assuming spacing = 24 on parent grid container
            marginLeft: 20,
            marginRight: 20,
            marginBottom: 0
        }
    },
    contentContainerPublic: {
        backgroundColor: "#ffffff", // f0f0f0
        // Special contentContainer for public pages - Footer is larger
        "& > div:first-child, & > form:first-child": {
            paddingBottom: 60
        }
    },
    publicBackground: {
        backgroundColor: "#ffffff"
    },
    contentContainerOpen: {
        [theme.breakpoints.up("lg")]: {
            paddingLeft: 325 // 325 = Width of Sidebar
        },
        [theme.breakpoints.down("md")]: {
            paddingLeft: 275 // 325 = Width of Sidebar
        },
        height: "100%" // This is set so the dashboard map can be 100%
    },
    contentContainerClosed: {
        paddingLeft: theme.spacing.unit * 7, // Width of Closed Sidebar
        height: "100%" // This is set so the dashboard map can be 100%
    }
});
//<Navigation />
// Div here is where the content goes? 
//<NotReady storeReady={navState.storeReady} />

/*
<Header authenticated />
                <Navigation />
                */
class AppContainer extends Component {
    render() {
        const {  children, classes, authenticated, navState } = this.props; //, well_id, id item={well_id:""},

        return authenticated ? ( 
            <div className={classes.appContainer}>
                <Header authenticated/>
                <Navigation />
                <div
                    className={classNames(
                        classes.contentContainer,
                        navState.navigationOpen ? classes.contentContainerOpen : classes.contentContainerClosed
                    )}>{ children }
                </div>
                <Footer />
            </div>
        ) : (
            <div className="Public">
                <Header />
                    <div className={classes.contentContainerPublic}>{children}</div>
                <Footer />
            </div>
            
        );
    }
}

AppContainer = connect(state => ({
    navState: state.nav,
    authState: state.auth
}))(AppContainer);

export default withStyles(styles)(AppContainer);

import React, { Component } from "react";
import { connect } from "react-redux";

import PageHeader from '../common/PageHeader';
import Button from "@material-ui/core/Button";

import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import * as authActions from "../auth/actions";



const styles = theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
});

class ObservationChart extends Component {
    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
        
    }
    render() {
        //const { type, filtermin, filtermax } = this.state; // Each of the types gets its own graph.
        const { item } = this.props;
        return (
            <div>
                <div>Chart Placeholder</div>
                <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>Graphs</ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Select
                            value={item.type}
                            onChange={this.handleChange}
                        >
                            <MenuItem value="bicarbonate">Bicarbonate</MenuItem>
                            <MenuItem value="boron">Boron</MenuItem>
                            <MenuItem value="calcium">Calcium</MenuItem>
                            <MenuItem value="calciumcarbonate">Calcium Carbonate</MenuItem>
                            <MenuItem value="carbonate">Carbonate</MenuItem>
                            <MenuItem value="chloride">Chloride</MenuItem>
                            <MenuItem value="electroconductivity">Electroconductivity</MenuItem>
                            <MenuItem value="manganese">Manganese</MenuItem>
                            <MenuItem value="magnesium">Magnesium</MenuItem>
                            <MenuItem value="napercent">Na %</MenuItem>
                            <MenuItem value="nitrate">Nitrate as Nitrogen</MenuItem>
                            <MenuItem value="ph">pH</MenuItem>
                            <MenuItem value="phosphorus">Phosphorus</MenuItem>
                            <MenuItem value="potassium">Potassium</MenuItem>
                            <MenuItem value="sar">Sodium Absorbtion Ratio (SAR)</MenuItem>
                            <MenuItem value="selenium">Selenium</MenuItem>
                            <MenuItem value="sodium">Sodium</MenuItem>
                            <MenuItem value="sulfate">Sulfate</MenuItem>
                            <MenuItem value="tds">Tds</MenuItem>
                            <MenuItem value="tdsecfactor">TDS / EC Factor</MenuItem>
                        </Select>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </div>
        );
    }
}

ObservationChart.defaultProps = {
    item: {
        type: "",
    }
}

class WaterLevel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded:false,
            item: []
        };
    }
    handleExport() {
        // Export well data to excel.
    }
    handleChartPrint() {
        // Print the current chart to PDF.
    }
    render() {
        /*
         * Water Quality
         */
        const { error, isLoaded, item, itemres } = this.state;
        const { authState } = this.props; 
        if (!authState) return "...";        
        if (error) {
            return <div>Error loading Wells</div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else {
            return (
                <div>
                    <PageHeader title={item.well_id + ' Well Volume Pumps'} />
                    <ObservationChart />
                    <Button
                        title={item.well_id}
                        onClick={() => this.handleNav(item, this.props.history)}
                    >Add New Observation</Button>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Actions</TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell>Bicarbonate <i>mg/l</i></TableCell>
                                <TableCell>Boron <i>mg/l</i></TableCell>
                                <TableCell>Calcium <i>mg/l</i></TableCell>
                                <TableCell>Calcium Carbonate <i>mg/l</i></TableCell>
                                <TableCell>Carbonate <i>mg/l</i></TableCell>
                                <TableCell>Chloride <i>mg/l</i></TableCell>
                                <TableCell>Electro-Conductivity <i>umhos/cm</i></TableCell>
                                <TableCell>Hardness</TableCell>
                                <TableCell>Manganese</TableCell>
                                <TableCell>Magnesium <i>mg/l</i></TableCell>
                                <TableCell>Na %</TableCell>
                                <TableCell>Nitrate as Nitrogen <i>mg/l</i></TableCell>
                                <TableCell>pH <i>std</i></TableCell>
                                <TableCell>Phosphorus</TableCell>
                                <TableCell>Potassium <i>mg/l</i></TableCell>
                                <TableCell>Sodium Absorption Rate (SAR)</TableCell>
                                <TableCell>Selenium <i>mg/l</i></TableCell>
                                <TableCell>Sodium <i>mg/l</i></TableCell>
                                <TableCell>Sulfate</TableCell>
                                <TableCell>TDS <i>mg/l</i></TableCell>
                                <TableCell>TDS/EC Factor</TableCell>
                                <TableCell>Comments</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody >
                            {itemres.map(it => (
                                <TableRow>
                                    <TableCell><Button
                                        title={it.id}
                                        onClick={() => this.handleNav(item, this.props.history)}
                                    >View/Edit</Button></TableCell>
                                    <TableCell>{it.date}</TableCell>
                                    <TableCell>{it.bicarbonate}</TableCell>
                                    <TableCell>{it.boron}</TableCell>
                                    <TableCell>{it.calcium}</TableCell>
                                    <TableCell>{it.calciumcarbonate}</TableCell>
                                    <TableCell>{it.carbonate}</TableCell>
                                    <TableCell>{it.chloride}</TableCell>
                                    <TableCell>{it.electroconductivity}</TableCell>
                                    <TableCell>{it.hardness}</TableCell>
                                    <TableCell>{it.manganese}</TableCell>
                                    <TableCell>{it.magnesium}</TableCell>
                                    <TableCell>{it.napercent}</TableCell>
                                    <TableCell>{it.nitrate}</TableCell>
                                    <TableCell>{it.ph}</TableCell>
                                    <TableCell>{it.phosphorus}</TableCell>
                                    <TableCell>{it.potassium}</TableCell>
                                    <TableCell>{it.sar}</TableCell>
                                    <TableCell>{it.selenium}</TableCell>
                                    <TableCell>{it.sodium}</TableCell>
                                    <TableCell>{it.sulfate}</TableCell>
                                    <TableCell>{it.tds}</TableCell>
                                    <TableCell>{it.tdsecfactor}</TableCell>
                                    <TableCell>{it.comments}</TableCell>
                                </TableRow> 
                            ))}
                        </TableBody>
                    </Table>
                </div>
                
            );
        }
    }
}
WaterLevel = connect(
    state => ({
        navState: state.nav,
        authState: state.auth
    }),
    {
        ...authActions
    }
)(WaterLevel);

export default withStyles(styles)(WaterLevel); //withStyles(styles)

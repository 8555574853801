import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom"; 
import { compose } from 'redux';
import DashboardMap from "../common/Map";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";

import AppContainer from "../common/AppContainer";
import * as authActions from "../auth/actions";

const styles = theme => ({
});
class WellsMap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded:false,
            items: []
        };
        this.handleNav = this.handleNav.bind(this);
    }
    handleCoords(lat, lng) { 
        const position = [lat, lng];
        return position;
    };
    handleBlur(input) {
        const { authState } = this.props;
        fetch("/scripts/autocomplete.php?q="+input, {
                headers: {
                    "Authorization":"Token " + authState.user.auth_token
                },
                credentials: "same-origin"
            })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.items[0].detail === undefined) {
                        this.setState({
                            isLoaded:true,
                            items: result.items
                        });
                    } else {
                        this.setState({
                            isLoaded:false,
                            items: result.items
                        });
                    }
                },
                (error) => {
                    this.setState({
                        isLoaded:true,
                        error
                    });
                }
            );
    }
    
    handleNav(item, history) {
        console.log('Navigating');
        console.log(item);
        history.push({pathname: '/welldetail', item:item});
    }
    
    render() {
        const { authState } = this.props; //navigation
        const instanceHeight = window.innerHeight - 97 + "px";
        if (!authState) return "...";
            return (
                <AppContainer authenticated>
                    <Grid container spacing={40} style={{"margin-top":"1px", "margin-left":"1px", "margin-right":"1px", "width":"100%", height:instanceHeight }}>
                        <DashboardMap style={{"margin-top":"50px", "height":"100%"}} containerheight={instanceHeight} />
                    </Grid>
                </AppContainer>
            );
    }
}

WellsMap = connect(
    state => ({ authState: state.auth }),
    {
        ...authActions
    }
)(WellsMap);

export default compose(
    withStyles(styles),
    withRouter
)(WellsMap)

import "./index.css";

import React from 'react';
import ReactDOM from 'react-dom';

import { HashRouter, Route, Switch } from "react-router-dom";
import { MuiThemeProvider, createMuiTheme, createGenerateClassName } from "@material-ui/core/styles";
import JssProvider from "react-jss/lib/JssProvider";

import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import { Provider as ReduxProvider } from "react-redux";
import { createReducer } from "redux-orm";
import { offline } from "@redux-offline/redux-offline";
import offlineConfig from "@redux-offline/redux-offline/lib/defaults";
import localForage from "localforage";
//import registerServiceWorker from "./registerServiceWorker";

import "whatwg-fetch";

import navReducer from "./components/common/reducers";
import saveReducer from "./components/charts/reducers";
import { storeReady } from "./components/common/actions";
import authReducer from "./components/auth/reducers";
import orm, { reloadAll, syncReducer } from "./components/common/orm"; //, { syncReducer } 
import WarningDialogWrapper from "./components/common/WarningDialogWrapper";

import LoginPage from "./components/auth/LoginPage";
import PasswordPage from "./components/auth/PasswordPage";

//import Dashboard from "./components/dashboard/Dashboard";

import WellsMap from "./components/wells/WellsMap";
import WaterVolume from "./components/wells/WaterVolume";
import WellsList from "./components/wells/WellsList";
import WellDetail from "./components/wells/WellDetail";
import MaintenanceLog from "./components/wells/MaintenanceLog";
import PumpTests from "./components/wells/PumpTests";
import WaterLevel from "./components/wells/WaterLevel";
import WaterQuality from "./components/wells/WaterQuality";

import PasswordReset from "./components/adm/PasswordReset";
import UserProfile from "./components/adm/UserProfile";
import RegistrationForm from "./components/adm/RegistrationForm";

import WellCreateForm from "./components/forms/WellCreateForm";
import MaintenanceForm from "./components/forms/MaintenanceForm";
import PumpTestForm from "./components/forms/PumpTestForm";
import WellVolumePumpForm from "./components/forms/WellVolumePumpForm";


// Skipping over the theme for now.
const theme = createMuiTheme({
    typography: {
        fontFamily: "'Source Sans Pro', sans-serif"
        //color: "#666666 !important" // Doesn't work?
    },
    overrides: {
        // Make the font color uniform
        MuiFormHelperText: {
            root: {
                lineHeight: "1.375em",
                color: "#666666"
            }
        },
        MuiFormLabel: {
            root: {
                fontSize: "16px",
                fontWeight: 300,
                color: "#666666"
            }
        },
        MuiInputLabel: {
            root: {
                fontSize: "16px",
                fontWeight: 300,
                color: "#666666 !important"
            },
            shrink: {
                transform: "translate(0, 0px) scale(1)"
            }
        },
        MuiFormControl: {
            root: {
                width: "100%",
            },
        },
        MuiFormControlLabel: {
            root: {
                alignItems: 'left',
            }
        },
        MuiListItem: {
            root: {
                fontSize: "16px",
                fontWeight: 300,
                color: "#666666"
            }
        },
        MuiTableSortLabel: {
            root: {
                whiteSpace: "nowrap"
            }
        },
        MuiTypography: {
            caption: {
                fontSize: 10
            },
            // Page Header
            display1: {
                fontSize: "24px",
                fontWeight: 100,
                color: "black"
            },
            //Username
            display2: {
                fontSize: "18px",
                fontWeight: 100,
                color: "#666666"
            },
            //Form group headings
            display3: {
                borderBottom: "2px solid #efefef",
                fontSize: "30px",
                fontWeight: 200,
                marginLeft: 0,
                letterSpacing: 0
            },
            //Form group heading no underline
            display4: {
                fontSize: "30px",
                fontWeight: 200,
                marginLeft: 0,
                letterSpacing: 0
            },
            // Smaller headings
            title: {
                fontWeight: 300,
                fontSize: "20px",
                color: "#666666"
            },
            // Normal text
            body1: {
                fontWeight: 300,
                fontSize: "16px",
                color: "#666666"
            },
            gutterBottom: {
                marginBottom: ".6em"
            }
        },
        // Table cell 13px weight 400 defined in common/tablecell
        MuiButton: {
            root: {
                textTransform: "none",
                fontSize: "18px",
                fontWeight: 100,
                color: "#1364f7"
            }
        },
        MuiTableRow: {
            root: {
                height: 40
            }
        }
    },
    palette: {
        primary: {
            // Blues
            light: "#ffb444",
            //main: "#ff8300",
            main: "#13aff1",
            dark: "#c55400",
            contrastText: "#fff"
        },
        secondary: {
            // FTM Greys
            light: "#5fc6ff",
            main: "#0096d6",
            dark: "#0068a4",
            contrastText: "#fff"
        }
    },
    formField: {
        margin: '0px',
        width: '100%',
        padding: '0px',
        borderRadius: '3px',
        /*margin: '0px',*/
    }
});
const generateClassName = createGenerateClassName({
    //dangerouslyUseGlobalCSS: true,
    productionPrefix: "c"
});

const logger = createLogger();
const reducer = combineReducers({
    auth: authReducer,
    orm: createReducer(orm),
    sync: syncReducer,
    nav: navReducer,
    saver: saveReducer,// Assume this is for the navigation menu. 
});

const initialState = {
    nav: {
        navigationOpen: true,
        libraryOpen: true,
        supportOpen: false,
        projectOpen: false,
        selectedPage: "dashboard",
        selectedButton: "table",
        wellSelected: false,
        well_id: false,
        welltime_id: false,
        id: false,
        sw: { checking: true }
    }
};


const middleware = process.env.NODE_ENV === "production" ? applyMiddleware(thunk) : applyMiddleware(thunk, logger);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    reducer,
    initialState,
    composeEnhancers(
        middleware,
        offline({
            ...offlineConfig,
            retry: (action, retries) => (retries > 3 ? null : retries * 1000),
            persistOptions: {
                storage: localForage,
                serialize: false
            },
            persistAutoRehydrate: () =>
                offlineConfig.persistAutoRehydrate({
                    stateReconciler: (state, inboundState) => {
                        // Don't carry over pending from previous session
                        // Loading icon becomes stuck
                        if (inboundState.auth && inboundState.auth.pending) {
                            inboundState.auth.pending = null;
                        }
                        // Don't wipe out sw notification if it happened during init
                        if (state.nav && state.nav.sw) {
                            inboundState.nav = {
                                ...inboundState.nav,
                                sw: state.nav.sw
                            };
                        }
                        inboundState.offline = {
                            ...state.offline,
                            ...inboundState.offline,
                            online: state.offline.online,
                            netInfo: state.offline.netInfo,
                            busy: state.offline.busy
                        };
                        inboundState.orm = {
                            ...state.orm,
                            ...inboundState.orm
                        };
                        return inboundState;
                    }
                }),
            persistCallback: () => {
                //const state = store.getState();
                store.dispatch(storeReady());
                //if (state && state.auth && state.auth.user && state.auth.user.auth_token)
                //    stopImpersonate(state.auth.user.auth_token);
                store.dispatch(reloadAll(true));
            }
        })
    )
);
/* Don't think we need this, Looks like it warns that the pages is about to change?  */
const getConfirmation = (message, callback) => {
    ReactDOM.render(
        <MuiThemeProvider theme={theme}>
            <WarningDialogWrapper message={message} callback={callback} />
        </MuiThemeProvider>,
        document.getElementById("pp")
    );
};

ReactDOM.render(
    <ReduxProvider store={store}>
        <JssProvider generateClassName={generateClassName}>
            <MuiThemeProvider theme={theme}>
                <HashRouter getUserConfirmation={getConfirmation}>
                    <Switch>
                        <Route exact path="/" component={LoginPage} />
                        <Route path="/dashboard" render={(props) => <WellsMap {...props} />} />
                        <Route path="/password/email" component={PasswordPage} />
                        <Route path="/password/reset" component={PasswordReset} />
                        <Route path="/userprofile" component={UserProfile} />
                        <Route path="/wellslist" render={(props) => <WellsList {...props}/>} />
                        {/*<Route path="/welldetail" render={(props) => <WellDetail {...props}/>} />*/}
                        <Route path="/welldetail/:id" render={(props) => <WellDetail {...props}/>} />
                        {/*render={(props) => <WellDetail {...props}/>} />*/}
                        <Route path="/wellcreate" render={(props) => <WellCreateForm {...props}/>} />
                        <Route path="/wellsmap" render={(props) => <WellsMap {...props} />} />
                        <Route path="/watervolume" render={(props) => <WaterVolume {...props}/>} />
                        <Route path="/waterquality" render={(props) => <WaterQuality {...props}/>} />
                        <Route path="/maintenancelog" render={(props) => <MaintenanceLog {...props}/>} />
                        <Route path="/pumptests" render={(props) => <PumpTests {...props}/>} />
                        <Route path="/waterlevel" render={(props) => <WaterLevel {...props}/>} />
                        <Route path="/maintenanceform" render={(props) => <MaintenanceForm {...props}/>} />
                        <Route path="/pumptestform" render={(props) => <PumpTestForm {...props}/>} />
                        <Route path="/watervolumepumping" render={(props) => <WellVolumePumpForm {...props}/>} />
                        <Route path="/register" render={(props) => <RegistrationForm {...props}/>} />
                    </Switch>
                </HashRouter>
            </MuiThemeProvider>
        </JssProvider>
    </ReduxProvider>, document.getElementById("root")
);
/*
 *<Route path="/login" component={LoginPage} />
 *<Route path="/activate/:uid/:token" component={AccountConfirmation
 *<Route path="/dashboard" component={Dashboard} />
 **/

//<Route path="/reset/confirm/:uid/:token" component={PasswordRecoverPage} />
//<Route path="/register" component={RegisterPage} />
//<Route path="/registered/:username" component={RegisteredPage} />
//<Route path="/updateuser" component={UpdatePage} />
//registerServiceWorker(store.dispatch);

import React, { Component } from "react";
import { connect } from "react-redux";
//import PropTypes from 'prop-types';

import PageHeader from '../common/PageHeader';
import Button from "@material-ui/core/Button";

import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

import AppContainer from "../common/AppContainer";
import * as authActions from "../auth/actions"; 
//import * as navActions from "../common/actions";


const styles = theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
});

class Graphs extends Component {
    constructor(props) {
        super(props);
        this.item = {
            error: null,
            isLoaded:false,
            item: []
        };
        this.handleNav = handleNavFunc.bind(this);
    }
    render() {
        const { item } = this.props;
        return (
            <div>
                <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>Graphs</ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Select
                            value={item.type}
                            onChange={this.handleChange}
                        >
                            <MenuItem value="bicarbonate">Water Level Elevation</MenuItem>
                            <MenuItem value="boron">Water Level Depth</MenuItem>
                            <MenuItem value="calcium">Measurement Depth</MenuItem>
                        </Select>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>Summary Statistics</ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <TextField
                            id="period"
                            label="Period"
                            value={item.period}
                        />
                        <TextField
                            id="groundwaterelev"
                            label="Ground Surface Elevation"
                            value={item.groundwaterelev}
                        />
                        <TextField
                            id="minval"
                            label="Minimum Value"
                            value={item.minval}
                        />
                        TextField
                            id="maxval"
                            label="Maximum Value"
                            value={item.maxval}
                        />
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>Filter Monitoring Data</ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        Start:
                        <Select
                            value={item.type}
                            onChange={this.handleChange}
                        >
                            <MenuItem value="1996">1996</MenuItem>
                            <MenuItem value="1997">1997</MenuItem>
                            <MenuItem value="1998">1998</MenuItem>
                            <MenuItem value="1999">1999</MenuItem>
                            <MenuItem value="2000">2000</MenuItem>
                            <MenuItem value="2001">2001</MenuItem>
                            <MenuItem value="2002">2002</MenuItem>
                            <MenuItem value="2003">2003</MenuItem>
                            <MenuItem value="2004">2004</MenuItem>
                            <MenuItem value="2005">2005</MenuItem>
                            <MenuItem value="2006">2006</MenuItem>
                            <MenuItem value="2007">2007</MenuItem>
                            <MenuItem value="2008">2008</MenuItem>
                            <MenuItem value="2009">2009</MenuItem>
                            <MenuItem value="2010">2010</MenuItem>
                            <MenuItem value="2011">2011</MenuItem>
                            <MenuItem value="2012">2012</MenuItem>
                            <MenuItem value="2013">2013</MenuItem>
                            <MenuItem value="2014">2014</MenuItem>
                            <MenuItem value="2015">2015</MenuItem>
                            <MenuItem value="2016">2016</MenuItem>
                            <MenuItem value="2017">2017</MenuItem>
                            <MenuItem value="2018">2018</MenuItem>
                            <MenuItem value="2019">2019</MenuItem>
                        </Select><br />
                        End:
                        <Select
                            value={item.type}
                            onChange={this.handleChange}
                        >
                            <MenuItem value="1996">1996</MenuItem>
                            <MenuItem value="1997">1997</MenuItem>
                            <MenuItem value="1998">1998</MenuItem>
                            <MenuItem value="1999">1999</MenuItem>
                            <MenuItem value="2000">2000</MenuItem>
                            <MenuItem value="2001">2001</MenuItem>
                            <MenuItem value="2002">2002</MenuItem>
                            <MenuItem value="2003">2003</MenuItem>
                            <MenuItem value="2004">2004</MenuItem>
                            <MenuItem value="2005">2005</MenuItem>
                            <MenuItem value="2006">2006</MenuItem>
                            <MenuItem value="2007">2007</MenuItem>
                            <MenuItem value="2008">2008</MenuItem>
                            <MenuItem value="2009">2009</MenuItem>
                            <MenuItem value="2010">2010</MenuItem>
                            <MenuItem value="2011">2011</MenuItem>
                            <MenuItem value="2012">2012</MenuItem>
                            <MenuItem value="2013">2013</MenuItem>
                            <MenuItem value="2014">2014</MenuItem>
                            <MenuItem value="2015">2015</MenuItem>
                            <MenuItem value="2016">2016</MenuItem>
                            <MenuItem value="2017">2017</MenuItem>
                            <MenuItem value="2018">2018</MenuItem>
                            <MenuItem value="2019">2019</MenuItem>
                        </Select>
                        <TextField
                            id="minInput"
                            label="Min"
                            value={item.minval}
                        />
                        <TextField
                            id="maxInput"
                            label="Max"
                            value={item.maxval}
                        />
                        <Button
                            title="Show Spring Results Only"
                            onClick={() => this.springResults(item, this.props.history)}
                        >Show Spring Results Only</Button>
                        <Button
                            title="Show Fall Results Only"
                            onClick={() => this.fallResults(item, this.props.history)}
                        >Show Fall Results Only</Button>
                        <Button
                            title="Show All Results"
                            onclick={() => this.allResults(item, this.props.history)}
                        >Show All Results</Button>
                        <Button
                            title="Export to Excel"
                            onclick={() => this.exportToExcel(item, this.props.history)}
                        >Export to Excel</Button>
                        <Button
                            title="Print Chart"
                            onclick={() => this.printChart(item, this.props.history)}
                        >Print Chart</Button>
                        
                            
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </div>
        )
    }
}

Graphs.defaultProps = {
    item: {
        type: "",
        period: "",
        groundwaterelev: "",
        minval: "",
        maxval: ""
    }
}

class ObservationChart extends Component {
    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
        // Rerender the chart.
        // Original chart code found in /app/js/kings/chartwidget.js
    }
    render() {
        //const { type, filtermin, filtermax } = this.props; // Each of the types gets its own graph.
        const { type=null } = this.props;
        return (
            <div>
                <div>Chart Placeholder</div>
                <Graphs />
                <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>Graphs</ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Select
                            value={type}
                            onChange={this.handleChange}
                        >
                            <MenuItem value="bicarbonate">Bicarbonate</MenuItem>
                            <MenuItem value="boron">Boron</MenuItem>
                            <MenuItem value="calcium">Calcium</MenuItem>
                            <MenuItem value="calciumcarbonate">Calcium Carbonate</MenuItem>
                            <MenuItem value="carbonate">Carbonate</MenuItem>
                            <MenuItem value="chloride">Chloride</MenuItem>
                            <MenuItem value="electroconductivity">Electroconductivity</MenuItem>
                            <MenuItem value="manganese">Manganese</MenuItem>
                            <MenuItem value="magnesium">Magnesium</MenuItem>
                            <MenuItem value="napercent">Na %</MenuItem>
                            <MenuItem value="nitrate">Nitrate as Nitrogen</MenuItem>
                            <MenuItem value="ph">pH</MenuItem>
                            <MenuItem value="phosphorus">Phosphorus</MenuItem>
                            <MenuItem value="potassium">Potassium</MenuItem>
                            <MenuItem value="sar">Sodium Absorbtion Ratio (SAR)</MenuItem>
                            <MenuItem value="selenium">Selenium</MenuItem>
                            <MenuItem value="sodium">Sodium</MenuItem>
                            <MenuItem value="sulfate">Sulfate</MenuItem>
                            <MenuItem value="tds">Tds</MenuItem>
                            <MenuItem value="tdsecfactor">TDS / EC Factor</MenuItem>
                        </Select>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </div>
        );
    }
}

function handleNavFunc(item, history) {
    console.log('pushing WaterLevel item: '+item);
    history.push({pathname: '/watervolumepumping', item:item});
}

class WaterLevel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded:false,
            item: []
        };
        this.handleNav = handleNavFunc.bind(this);
    }
    componentDidMount() {
        const { authState, navState } = this.props;
        const item = {
            id: navState.id,
            well_id: navState.well_id
        };
        fetch("/wellqualities/?well="+item.id, {
                headers: {
                    "Authorization":"Token " + authState.user.auth_token
                },
                credentials: "same-origin"
            })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.list.length === 0) {
                        this.setState({
                            isLoaded:true,
                            itemres: [],
                            item: item
                        });
                    } else {
                        console.log(result);
                        this.setState({
                            isLoaded:true,
                            itemres: result.list,
                            item: item
                        });
                    }
                },
                (error) => {
                    this.setState({
                        isLoaded:false,
                        error
                    });
                }
            )
    }
    handleExport() {
        // Export well data to excel.
    }
    handleChartPrint() {
        // Print the current chart to PDF.
    }
    render() {
        const { error, isLoaded, item, itemres } = this.state;
        const { authState } = this.props; // navState
        if (!authState) return "...";        
        if (error) {
            return <AppContainer>Error loading Wells</AppContainer>;
        } else if (!isLoaded) {
            return <AppContainer>Loading...</AppContainer>;
        } else {
            return (
                <AppContainer authenticated>
                    <PageHeader title={item.well_id + ' Well Volume Pumps'} />
                    <ObservationChart />
                    <Button
                        title={item.well_id}
                        onClick={() => this.handleNav(item, this.props.history)}
                    >Add New Observation</Button>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Actions</TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell>Sampled By</TableCell>
                                <TableCell>Measurement Depth</TableCell>
                                <TableCell>Water Level Depth</TableCell>
                                <TableCell>Water Level Elevation</TableCell>
                                <TableCell>Static/Pumping</TableCell>
                                <TableCell>Comments</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody >
                            {itemres.map(it => (
                                <TableRow>
                                    <TableCell><Button
                                        title={it.id}
                                        onClick={() => this.handleNav(item, this.props.history)}
                                    >View/Edit</Button></TableCell>
                                    <TableCell>{it.date}</TableCell>
                                    <TableCell>{it.sampledby}</TableCell>
                                    <TableCell>{it.measurement}</TableCell>
                                    <TableCell>{it.waterdepth}</TableCell>
                                    <TableCell>{it.groundwaterelev}</TableCell>
                                    <TableCell>{it.staticpumping}</TableCell>
                                    <TableCell>{it.comments}</TableCell>
                                </TableRow> 
                            ))}
                        </TableBody>
                    </Table>
                </AppContainer>
                
            );
        }
    }
}
WaterLevel = connect(
    state => ({
        navState: state.nav,
        authState: state.auth
    }),
    {
        ...authActions
    }
)(WaterLevel);

export default withStyles(styles)(WaterLevel); //withStyles(styles)

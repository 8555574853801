import React, { Component } from "react";
//import { withRouter } from "react-router-dom"; 
import classNames from "classnames";
//import { ListItem, ListItemIcon, ListItemText } from "material-ui/List";
//import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
//import IconButton from "material-ui/IconButton";
//import Menu from "material-ui/Menu";
import { withStyles } from "@material-ui/core";
//import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
//import Button from "@material-ui/core/Button";

//import { connect } from "react-redux";
//import * as authActions from "../auth/actions";
//import * as navActions from "./actions";
//import { reloadAll } from "./orm";

const styles = {
    header: {
        backgroundColor: "#eeeeee",
        margin: "0 -20px",
        padding: "20px 30px !important",
        flexBasis: "auto",
        width: "100vw",
        maxWidth: "105%"
    },
    dashboard: {
        borderBottom: "3px solid #00adee"
    },
    field: {
        borderBottom: "3px solid #34a853"
    },
    project: {
        borderBottom: "3px solid #ff7d32"
    },
    crop: {
        borderBottom: "3px solid #7ac143"
    },
    support: {
        borderBottom: "3px solid #aaaaaa"
    },
    fontSize: {
        fontSize: 11.5
    },
    marginTop: {
        marginTop: -18,
        marginBottom: -12
    }
};
/*
const logOut = () => {
    this.props.authLogout();
    this.props.history.push("/");
};
*/
/*
 *<h1 style={{ "font-weight": "bold", "font-size":"larger", "text-align":"center", "width":"100%" }} >{ title }</h1>*/
class PageHeader extends Component {
    //{user.first_name}
    render() {
        const { classes, title, color, crumbs} = this.props;
        return ( // Logged In
            <Grid item xs={12} className={classNames(classes.header, classes[color])}>
                {!crumbs && <Typography variant="display1">{title}</Typography>}
                {crumbs && (
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <Typography variant="display1">{title}</Typography>
                        </Grid>
                        <Grid item xs={12} className={classes.marginTop}>
                            <Typography variant="caption" className={classes.fontSize}>
                                {crumbs}
                            </Typography>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        );
    }
}
export default withStyles(styles)(PageHeader);

import React from "react";
//import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from '@material-ui/core/Paper';
//import { CircularProgress } from "@material-ui/core/Progress";
import { withStyles } from "@material-ui/core/styles";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import MaterialIcon from 'material-icons-react';
import TextField from '@material-ui/core/TextField';


const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    filterInput: {
        padding: '3px 12px',
        width: '90%',
    },
    /*
    paper: {
        padding: '2px 17px',
        paddingRight: '22px',
        paddingLeft: '20px',
        float: 'left',
        margin: '15px -3px',
        marginBottom: '8px',
        height: '64px',
    },
    selectClass: {
        width: '30%',
        margin: '0 10px',
    },
    bigIcon: {
        margin:"3px 25px",
        padding:"9px",
        transform:"scale(1.5)",
    },
    */
    
});
/*
const handleChange1 = function(item) {
    // update the wells based on the wellID
    console.log(item);
};
*/
/*const toggleMap = function(item) {
    console.log(item);
    //this.setState({filterwell: !this.state.filterwell});
};
*/
const doNone = function() {
    console.log('doing nothing');
}

class FilterControl extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            wellFilterActive: false,
            wellFilterValue: "",
        }
    }
    wellToggle = (e) => {
        if (e.target.value === "Well ID" || e.target.value === "State Well") {
            this.setState({
                wellFilterActive: true,
                wellFilterValue: e.target.value,
            });
        } else {
            this.setState({
                wellFilterActive: false,
                wellFilterValue: e.target.value,
            });
            this.props.applyFilters("Well ID", e); // Sets back to blank. 
        }
    }
    
    passFilters = column => event => {
        console.log(column);
        console.log(event);
        this.props.applyFilters(column, event);
    }
    // #1 style={{"margin-left":"10px", "margin-right":"10px", "width":"100%", "min-height":"48px"}} 
    // #2 style={{"background-color":"#f0f0f0", "width":"73%", "padding":"10px", "height":"65px"}}
    // #3 style={{"float":"left", "width":"18%", "margin-left":"11px", "background-color":"#f0f0f0"}}
    // className={classes.paper}
    render() {
        const { classes, toggleMap, selectedSection,} = this.props; // applyFilters, wellFilterValue
        
        return (
            <Grid container className={classes.root} justify="center" style={{ "width":"99%", "margin":"0 auto" }} spacing={24}>
                <Grid item xs={10}>Filter by Category</Grid>
                <Grid item xs={2}>Select View</Grid>
                <Grid item xs={10}>
                    <Grid container className={classes.root} spacing={24}>
                        <Grid item xs={4}>
                            <Paper>
                                <Select
                                    className={classes.filterInput}
                                    fullWidth
                                    value={this.state.wellFilterValue}
                                    onChange={this.wellToggle.bind(this)}
                                >
                                    <MenuItem value=""></MenuItem>
                                    <MenuItem value={"Well ID"}>Well ID</MenuItem>
                                    <MenuItem value={"State Well"}>State Well</MenuItem>
                                </Select>
                                <TextField
                                    className={classes.filterInput}
                                    style={{"display": this.state.wellFilterActive ? 'block' : 'none'}}
                                    fullWidth
                                    value={this.state.wellFilterValue === 'Well ID' ? this.props.filterWellId : this.props.filterStateWell}
                                    onChange={this.state.wellFilterValue === 'Well ID' ? this.passFilters("Well ID") : this.passFilters('State Well')}
                                    type="text"
                                />
                            </Paper>
                        </Grid>
                        <Grid item xs={4}>
                            <Paper>
                                <Select
                                    className={classes.filterInput}
                                    fullWidth
                                    value={this.props.filterAgency}
                                    onChange={this.passFilters('Agency')}
                                >
                                    {this.props.agencyOpts.map(item =>
                                       <MenuItem value={item}>{item}</MenuItem>
                                    )}
                                </Select>
                            </Paper>
                        </Grid>
                        <Grid item xs={4}>
                            <Paper>
                                <Select
                                    className={classes.filterInput}
                                    fullWidth
                                    value={this.props.filterWellType}
                                    onChange={this.passFilters('Well Type')}
                                >
                                    {this.props.wellTypeOpts.map(item =>
                                       <MenuItem value={item}>{item}</MenuItem>
                                    )}
                                </Select>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={2}>
                    <Paper>
                        <Button onClick={selectedSection ? toggleMap : doNone}>
                            <MaterialIcon
                                icon="map"
                                color="blue"
                                style={{"transform":"scale(1.5)",}}
                            />
                        </Button>
                        <Button onClick={selectedSection ? doNone : toggleMap}>
                            <MaterialIcon
                                icon="list"
                                color="blue"
                                style={{"transform":"scale(1.5)",}}
                            />
                        </Button>
                    </Paper>
                </Grid>
            </Grid>
        )
    }
};

export default withStyles(styles)(FilterControl);


/*
 *
4. Breadcrumbs Links:
background-color: inherit
color: black
border-color:blue
double-right-arrow

:first-child
right_arrow
*/
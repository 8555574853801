import React, { Component } from "react";
import { withRouter } from "react-router-dom"; // Link
//import classNames from "classnames";
import { Link } from "react-router-dom";

import AccountCircle from "@material-ui/icons/AccountCircle";
//import NotificationsIcon from "@material-ui/icons/Notifications";
//import SettingsIcon from "@material-ui/icons/Settings";
import ExitIcon from "@material-ui/icons/ExitToApp";
//import EditIcon from "@material-ui/icons/Edit";
//import GroupAddIcon from "@material-ui/icons/GroupAdd";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText  from "@material-ui/core/ListItemText";
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import { withStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import pack from "../../../package.json";

import MaterialIcon from 'material-icons-react';

import { connect } from "react-redux";
import * as authActions from "../auth/actions";
import * as navActions from "./actions";
import { reloadAll } from "./orm";

const styles = {
    appLogo: {
        width: 232,
        marginTop: -5,
        marginBottom: -5
    },
    divWrapper: {
        width: "70%",
        textAlign: "right"
    },
    inlineRight: {
        display: "inline-block",
        marginRight: "40px",
        marginTop: 22
    },
    inlineLeft: {
        display: "inline-block",
        marginLeft: "20px",
        marginTop: 17,
        marginBottom: 17,
        float: "left"
    },
    inlineRightImpersonate: {
        display: "inline-block",
        marginRight: "40px"
    },
    impersonateColor: {
        fontWeight: 900
    },
    impersonateContainer: {
        marginTop: -8
    },
    headline: {
        textAlign: "right",
        zIndex: 2
    },
    zIndex: {
        zIndex: 1300,
        height: "50px",
        minHeight: "50px",
    },
    listItem: {
        backgroundColor: "#eaeaea",
        borderBottom: "1px solid #666666",
        padding: "5px",
    },
    alertText: {
        color: "#666666"
    },
    topListItem: {
        borderBottom: "1px solid #666666"
    },
    topAlertText: {
        color: "#666666",
        fontSize: "21px",
        fontWeight: "200"
    },
    list: {
        "& ul": {
            padding: 0
        }
    },
    updateReady: {
        color: "#fff",
        backgroundColor: "#4e4e4e",
        position: "absolute",
        width: "100%"
    },
    zIndexRefresh: {
        zIndex: 3
    },
    zIndexLogo: {
        zIndex: -1
    },
    materialicon: {
        width: "0px",
        paddingRight: "25px",
    },
};

class Header extends Component {
    constructor() {
        super();
        this.state = {
            userMenuAnchor: null
        };
    }

    handleUserMenu = event => {
        this.setState({ userMenuAnchor: event.currentTarget });
    };

    handleUserMenuClose = () => {
        this.setState({ userMenuAnchor: null });
    };

    logOut = () => {
        this.props.well_selected(undefined, undefined);
        this.props.authLogout();
        this.props.navSelectPage("/");
        this.props.history.push("/");
    };

    editUserProfile = () => {
        this.props.history.push('/userprofile');
    };
    
    administrate = () => {
        this.props.history.push('/administrate');
    };
    
    restoreAccess = () => {
        //const { authState, reload } = this.props;
    };

    render() {
        const { classes, authenticated, authState, navState  } = this.props; //navSelectPage
        const { userMenuAnchor } = this.state;
        const userMenuOpen = Boolean(userMenuAnchor);

        if (!authState) return "...";

        var { user } = authState;
        if (!user) {
            user = {};
            if (authenticated) {
                // Page requires authentication
                if (!navState.storeReady) {
                    // Give store a chance to reload
                    user.first_name = "...";
                } else {
                    user.first_name = "Not Logged In!";
                }
            }
        }

        // real user is not a guest and not impersonating
        // if a user is a guest user.guest = true
        // We don't have a guest or impersonator. Should be true if logged in, right? 
        //const isRealUser = true; //!(user.guest ? user.guest : false) && !(user._impersonate ? user._impersonate : false);

        //const showUnused = false;
        const updateReady = navState.sw && navState.sw.update;

        const UpdateHeader = () => ( // Don't think we need this at all. 
            <div style={{"padding-bottom":"28px"}}></div>
        );

        return authenticated ? ( // Logged In
            <AppBar position="fixed" style={{"min-height":"50px", "max-height":"50px"}} className={classes.zIndex}>
                {updateReady && <UpdateHeader />}
                <Toolbar style={{"max-height":"50px", "min-height":"50px"}} >
                    <Grid container spacing={24}>
                        <Grid item xs={12} className={classes.headline}>
                            <Typography variant="display1" color="#fff" className={classes.inlineLeft}>
                                <Link style={{"text-decoration":"none"}} to="/dashboard">Kings Wells Database</Link>
                            </Typography>
                            <IconButton
                                aria-owns={userMenuOpen ? "menu-appbar" : null}
                                aria-haspopup="true"
                                onClick={this.handleUserMenu}
                                color="inherit">
                                <AccountCircle />
                            </IconButton>
                            <Typography variant="display2" color="#fff" className={classes.inlineRight}>
                                Hi, {user.first_name}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Menu
                        id="menu-appbar"
                        anchorEl={userMenuAnchor}
                        classes={{
                            paper: classes.list
                        }}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right"
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right"
                        }}
                        open={userMenuOpen}
                        onClose={this.handleUserMenuClose}>
                        <ListItem title="User Options" button className={classes.topListItem}>
                            <ListItemText
                                primary="User Options"
                                classes={{
                                    primary: classes.topAlertText
                                }}
                            />
                        </ListItem>
                        <ListItem title="Administration" button  onClick={this.administrate} className={classes.listItem}>
                            <ListItemIcon className={classes.materialicon}>
                                <MaterialIcon icon="people" />
                            </ListItemIcon>
                            <ListItemText
                                inset
                                primary="Administration"
                                classes={{
                                    primary: classes.alertText
                                }}
                            />
                        </ListItem>
                        <ListItem title="Update Profile" button onClick={this.editUserProfile} className={classes.listItem}>
                            <ListItemIcon className={classes.materialicon}>
                                <MaterialIcon icon="create" />
                            </ListItemIcon>
                            <ListItemText
                                inset
                                primary="Update Profile"
                                classes={{
                                    primary: classes.alertText
                                }}
                            />
                        </ListItem>
                        <ListItem title="Log Out" button onClick={this.logOut} className={classes.listItem}>
                            <ListItemIcon className={classes.alertText}>
                                <ExitIcon />
                            </ListItemIcon>
                            <ListItemText
                                inset
                                primary="Log Out"
                                classes={{
                                    primary: classes.alertText
                                }}
                            />
                        </ListItem>
                        <ListItem title={"Version " + pack.version} button className={classes.listItem}>
                            <ListItemIcon className={classes.materialicon}>
                                <MaterialIcon icon="keyboard_arrow_right" />
                            </ListItemIcon>
                            <ListItemText
                                inset
                                primary={"Version " + pack.version}
                                classes={{
                                    primary: classes.alertText
                                }}
                            />
                        </ListItem>
                    </Menu>
                </Toolbar>
            </AppBar>
        ) : (
            // Public
            <AppBar position="fixed" className={classes.zIndex}>
                {updateReady && <UpdateHeader />}
                <Toolbar>
                    <Grid container spacing={24}>
                        <Grid item xs={9} className={classes.headline}>
                            <div style={{"padding-bottom":"28px"}}>
                                <Typography variant="display1" style={{"color":"black", "margin-top":"0px"}} className={classes.inlineLeft}>
                                    Kings Wells Database
                                </Typography>
                                <div className={classes.divWrapper}>
                                    <Typography variant="caption" color="inherit" style={{"position":"absolute", "right":"10px", "top":"30px"}}>
                                        Version {pack.version}
                                    </Typography>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        );
    }
}
/* <Link to="/" className={classes.zIndexLogo}>
                        <img src="" alt="Kings Wells Database" className={classes.appLogo} />
                    </Link>*/

Header = connect(
    state => ({ authState: state.auth, navState: state.nav }),
    {
        ...authActions,
        ...navActions,
        reload: reloadAll
    }
)(Header);

export default withStyles(styles)(withRouter(Header));

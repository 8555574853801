import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from 'redux';
import {  Well, WellTime as WellTimeModel, WellParameter, WellCustom, } from "./models"; 
import { Link } from "react-router-dom";
import { createSelector } from "../common/orm";
import Button from "@material-ui/core/Button";
import ViewList from "@material-ui/icons/ViewList"; // Maintenance Log
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Waves from "@material-ui/icons/Waves"; // Waves
import Check from "@material-ui/icons/Check"; //water quality
import Typography from "@material-ui/core/Typography";

import { withStyles } from "@material-ui/core/styles";

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AppContainer from "../common/AppContainer";
import * as authActions from "../auth/actions";
import * as navActions from "../common/actions";
import BreadcrumbNav from "../common/BreadCrumb";
import Grid from "@material-ui/core/Grid";
import FlexibleForm from "../common/flexibleForm";

import TimeSeriesChart from "../charts/TimeSeriesChart";
import TimeSeriesTable from "../charts/TimeSeriesTable";
import { ChartFilter, ChartToggle } from "../charts/ChartFilter";
import Paper from '@material-ui/core/Paper';
import MaterialIcon from 'material-icons-react';

import WaterVolume from './WaterVolume';
import WaterQuality from "./WaterQuality";
import PumpTests from './PumpTests';
import MaintenanceLog from './MaintenanceLog';
import { welltimesconfig, MainChartConfig, WellRefConfig } from '../../api/formConfigs';

const getWell = createSelector(
    (state) => state,
    (session, state) => {
        if (state.nav.id === false || state.nav.id === "") {
            return session.Well.defaultData[0].ref;
        } else {
            return session.Well.withId(state.nav.id);
        }
    }
);
/*
const getWellResults = createSelector(
    (state) => state,
    (session, state) => {
        if (state.nav.id === false || state.nav.id === "" || session.WellResult.length === 0) {
            return session.WellResult.defaultData[0].ref;
        } else {
            return session.WellResult.withId(state.nav.id);
        }
    }
);
*/
const getWellRef = createSelector(
    (state) => state,
    (session, state) => {
        if (state.nav.id === false || state.nav.id === "") {
            return session.WellReference.defaultData[0].ref;
        } else {
            if (session.WellReference
                .filter(wellref => wellref.well_id === state.nav.id)
                .toModelArray() === undefined) {
                return session.WellReference.defaultData[0].ref;
            } else {
                return session.WellReference
                    .filter(wellref => wellref.well_id === state.nav.id)
                    .toModelArray()[0]
                    .ref;
            }
        }
    }
);

const styles = theme => ({
    svgStyle: {
        width: '24px',
        height: '24px',
        paddingRight: '18px',
    },
    panel: {
        width:"99%",
        margin:"0 auto",
    }
});

class WellDetail extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            ...props,
            welltimes: [],
            wellParams: [],
            wellCustom: [], // values for flexible form. 
            customData: [], // Values for interchange with server
            Fall: false,
            Spring: false,
            All: true,
            waterdepth: true,
            groundwaterelev: true,
            measurement: true,
            isChartView: true,
            isEdit: false,
            markerpos: this.props.well !== undefined ? [this.props.well.location.coordinates[0], this.props.well.location.coordinates[1]] : false,
            editable: false,
            locationState: 'current location (use gps)',
        };
    }
    handleNav(item, history) {
        history.push({pathname: '/watervolume', item:item});
    }
    handlePlots(plot) {
        this.setState({ [plot] : !this.state[plot] });
    }
    toggleEdit() {
        const { authState } = this.props;
        if (authState.user.is_staff) {
            this.setState({isEdit: !this.state.isEdit});        
        }
    }
    
    componentDidMount() {
        this.loadWellTimes({});
        this.loadWellParams({});
        this.loadWellCustom({});
    }
    
    componentDidUpdate(prevProps) {
        this.loadWellTimes(prevProps);
        this.loadWellParams(prevProps);
        this.loadWellCustom(prevProps);
    }
    toggleChart(item) {
        this.setState({isChartView: !this.state.isChartView});
    };
    doNone(item) {
        // pass
    }
    
    changeLocationEvent = value => {
        const { well } = this.props;
        if (value === 'current location (use gps)') {
            const location = window.navigator && window.navigator.geolocation;
            if (location) {
                var curpos = location.watchPosition((position) => {
                    this.setState({
                        markerpos: [
                            position.coords.latitude,
                            position.coords.longitude,
                            ] 
                    });
                    well.set('latitude', position.coords.latitude);
                    well.set('longitude', position.coords.longitude);
                    well.set('location', {type:"Point", coordinates:[position.coords.latitude, position.coords.longitude]});
                    well.set('location_coordinates', '{type:"Point", coordinates:['+position.coords.latitude+', '+position.coords.longitude+']}');
                }, (error) => {
                    this.setState({
                        Latitude: 0,
                        Longitude: 0,
                    });
                });
                setTimeout(function() {location.clearWatch(curpos)}, 5000);
            }
        }
        else if (value === 'click point on map') {
            this.setState({
                editable:true,
                markerpos: [well.location.coordinates[0], well.location.coordinates[1]]
            });
        }
        else if (value === 'enter latitude/longitude') {
            this.setState({
                editable:false,
                markerpos: [well.location.coordinates[0], well.location.coordinates[1]],
            });
        }
        this.setState({
            locationState: value,
        });
    }
    
    updatePosition = (e) => {
        const { well } = this.props;
        this.setState({
            markerpos: [e.latlng.lat, e.latlng.lng]
        });
        well.set('latitude', e.latlng.lat);
        well.set('longitude', e.latlng.lng);
        well.set('location', {type:"Point", coordinates:[e.latlng.lat, e.latlng.lng]});
        well.set('location_coordinates', '{type:"Point", coordinates:['+e.latlng.lat+', '+e.latlng.lng+']}');
    }
    
    loadWellCustom(prevProps, filter='') {
        const { well, ormWellCustomLoadRelated } = this.props, // authState, welltimes
            { well: lastWell } = prevProps;
        if (!well) {
            return;
        }
        if (!lastWell || lastWell.id !== well.id || filter !== '') {
            if (filter !== '')
                filter = '&'+filter.toLowerCase()+'=True';
            ormWellCustomLoadRelated("well_id="+well.id+filter, data => {
                this.setState({
                    wellCustom: this.mapCustom(data),
                    customData: data,
                });
                return data;
            });
        }
    }
    
    loadWellTimes(prevProps, filter='') {
        const { well, ormWellTimeLoadRelated } = this.props, // authState, welltimes
            { well: lastWell } = prevProps;
        if (!well) {
            return;
        }
        if (!lastWell || lastWell.id !== well.id || filter !== '') {
            if (filter !== '')
                filter = '&'+filter.toLowerCase()+'=True';
            ormWellTimeLoadRelated("well_id="+well.id+filter, data => {
                this.setState({welltimes: data});
                return data;
            });
        }
    }
    
    loadWellParams(prevProps, filter='') {
        const { well, ormWellParameterLoadRelated } = this.props, // authState, welltimes
            { well: lastWell } = prevProps;
        if (!well) {
            return;
        }
        if (!lastWell || lastWell.id !== well.id || filter !== '') {
            if (filter !== '')
                filter = '&'+filter.toLowerCase()+'=True';
            ormWellParameterLoadRelated("well_id="+well.id+filter, data => {
                this.mapParamsToFieldConf(data);
                return data;
            });
        }
    }
    
    mapParamsToFieldConf = params => {
        /* Immutable object. Doesn't need to be reversed */
        let fieldconf = [];
        Object.values(params).forEach((param) =>
            {
                var type_label = 'text';
                var options = '';
                if (param.type_label === "Drop Down") {
                    type_label = 'dropdown';
                    options = param.values.split(', ');
                }
                else if (param.type_label === "Checkbox") {
                    type_label = 'radio';
                    options = ["on", "off"];
                }
                else if (param.type_label === "Numeric")
                    type_label = 'numeric';
                    
                fieldconf.push({
                    id: param.label, // Label, beause that's what needs to match the row. Prev: id,
                    numeric: param.type_label === "numeric" ? true : false,
                    label: param.label,
                    hidden: false,
                    dataType: type_label,
                    options: options, // TODO: make options from comma-separated string.
                    db_id: param.id,
                    db_type: param.type_label,
                });
                if (fieldconf.length === params.length)
                    this.setState({ wellParams: fieldconf });
                    return fieldconf;
            });
    }
    
    mapCustom = custom => {
        let returnObj = {};
        Object.values(custom).forEach(
            (row) => {
                returnObj[row.type_label] = row.value;
                if (Object.keys(returnObj).length === custom.length)
                    return returnObj;
            }
        );
        return returnObj;
    }
    
    handleChange = name => event => {
        this.setState({ [name]: event.target.checked });
        if ((name === 'Fall' || name === 'Spring') && event.target.checked)
            this.setState({ 'All': false });
        if (name === 'All' && event.target.checked)
            this.setState({ 'Fall': false, 'Spring': false })
        if (event.target.checked)
            this.loadWellTimes(this.props, name);
    };
    
    changeLat = value => {
        const { well } = this.props;
        well.set('latitude', value);
        well.set('location', {type:"Point", coordinates:[value, well.ref.longitude]});
        well.set('location_coordinates', '{type:"Point", coordinates:['+value+', '+well.ref.longitude+']}');
        this.setState({
            markerpos: [value, well.ref.longitude]
        });
    }
    
    changeLng = value => {
        const { well } = this.props;
        well.set('longitude', value);
        well.set('location', {type:"Point", coordinates:[well.ref.latitude, value]});
        well.set('location_coordinates', '{type:"Point", coordinates:['+well.ref.latitude+', '+value+']}');
        this.setState({
            markerpos: [well.ref.latitude, value]
        });
    }
    
    saveFlexible = values => {
        const { ormWellUpdate } = this.props;
        ormWellUpdate(values);
    }
    unMapCustom = (fields, is_update) => {
        const { customData, wellParams } = this.state;
        const { well } = this.props;
        Object.keys(fields).forEach(
            (field, i) => {
                if (is_update) {
                    customData.filter((cust) =>
                                field === cust.type_label
                            )[0].value = Object.values(fields)[i];
                } else {
                    Object.values(wellParams).filter((cust) =>
                                field === cust.id
                            )[0].value = Object.values(fields)[i];
                }
            }
        );
        if (!is_update) {
            // Add the well_id to the fields object.
            Object.values(wellParams).forEach(param => {
                // param.dataType <- delete
                // param.hidden <- delete
                // param.id => ??? Needs to be deleted.
                // param.label <- "Test Text (text)" => "Result object (14)"
                // param.numeric <- delete
                // param.options <- delete
                // param.value ... keep
                // param.type_id => id of the type parameter
                param.id = null; // Set to null and let the database handle this since it's new.
                param.type_id = param.db_id;
                param.type_label = param.db_type; 
                param.well_label = well.label;
                param.type_label = param.label;
                param.well_id = well.id;
            })
            return wellParams; // fields;
        } else {
            return customData;
        }
    }
    saveCustom = (values, is_update) => {
        const { ormWellCustomUpdate, ormWellCustomCreate } = this.props;
        values = this.unMapCustom(values, is_update);
        if (is_update) {
            Object.values(values).forEach(val => ormWellCustomUpdate(val))
        } else {
            const { well } = this.props;
            values['well_id'] = well.id; // Fingers crossed!
            Object.values(values).forEach(val => ormWellCustomCreate(values));
        }
    }
    saveWellRef = values => {
        const { ormWellRefUpdate } = this.props;
        console.log(values);
    }
    render() {
        const { authState, classes, well, wellRef } = this.props; //isEdit=false wellResults, wellRef
        const { isChartView, isEdit, welltimes, wellParams, wellCustom } = this.state;
        if (!authState || well === undefined) {
            return (<AppContainer authenticated>Loading...</AppContainer>) 
        } else {
            return (
                <AppContainer authenticated>
                    <BreadcrumbNav level1='Groundwater Wells' level1link='/wellslist' level2={well.well_id} level2link='' />
                    <ExpansionPanel className={classes.panel} >
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            <svg className={classes.svgStyle} viewBox="0 0 24 24">
                                <path fill="#000000" d="M22,4H14V7H10V4H2A2,2 0 0,0 0,6V20A2,2 0 0,0 2,22H22A2,2 0 0,0 24,20V6A2,2 0 0,0 22,4M8,9A2,2 0 0,1 10,11A2,2 0 0,1 8,13A2,2 0 0,1 6,11A2,2 0 0,1 8,9M12,17H4V16C4,14.67 6.67,14 8,14C9.33,14 12,14.67 12,16V17M20,18H14V16H20V18M20,14H14V12H20V14M20,10H14V8H20V10M13,6H11V2H13V6Z" />
                            </svg>
                            <Typography>Identification/Location</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Grid container spacing={24}>
                                <Grid item xs={10}>
                                    {(isEdit && authState.user.is_staff) ?
                                        <>
                                            <FlexibleForm
                                                fieldconf={MainChartConfig}
                                                fields={well.ref}
                                                saveFlexible={this.saveFlexible.bind(this)}
                                                handleChange={this.handleChange.bind(this)}
                                                updatePosition={this.updatePosition}
                                                changeLocationEvent={this.changeLocationEvent}
                                                markerpos={this.state.markerpos}
                                                locationState={this.state.locationState}
                                                changeLat={this.changeLat}
                                                changeLng={this.changeLng}
                                                editable={this.state.editable}
                                                hasMap={true}
                                                />
                                            
                                            <Typography>Custom Attributes</Typography>
                                            {/* We need to be able to add a blank form if wellCustom==[] */}
                                            <FlexibleForm
                                                fieldconf={wellParams}
                                                fields={wellCustom}
                                                isEdit={isEdit}
                                                saveFlexible={this.saveCustom}
                                                handleChange={this.handleChange.bind(this)}
                                                hasMap={false}
                                            />
                                                
                                        </>
                                            
                                    :
                                        <Grid container spacing={12}>
                                            <Grid item xs={6}>
                                                Well ID: {well.well_id}
                                            </Grid>
                                            <Grid item xs={6}>
                                                State Well Number: {well.state_well}
                                            </Grid>
                                            <Grid item xs={6}>
                                                Agency: {well.agency}
                                            </Grid>
                                            <Grid item xs={6}>
                                                Local Agency Well ID: {well.agency_well_id}
                                            </Grid>
                                            <Grid item xs={6}>
                                                Well Type: {well.well_type}
                                            </Grid>
                                            <Grid item xs={6}>
                                                Volumetric Pumping Calculations: {well.volumetric}
                                            </Grid>
                                            <Grid item xs={6}>
                                                Notes: {well.notes}
                                            </Grid>
                                        </Grid>
                                    }
                                </Grid>
                                <Grid item xs={2}>
                                    <Paper className={classes.paper} style={{"background-color":"#f0f0f0", }} >
                                        <Button onClick={!isEdit ? this.toggleEdit.bind(this) : this.doNone.bind(this)}>
                                            <MaterialIcon
                                                icon="edit"
                                                color="blue"
                                                style={{"float":"left"}}
                                            />
                                        </Button>
                                        <Button onClick={!isEdit ? this.doNone.bind(this) : this.toggleEdit.bind(this)}>
                                            <MaterialIcon
                                                icon="remove_red_eye"
                                                color="blue"
                                                style={{"float":"left"}}
                                            />
                                        </Button>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    
                    <ExpansionPanel className={classes.panel}>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            {!isChartView ?
                                <MaterialIcon
                                    icon="list"
                                    color="black"
                                    style={{"padding-right": "18px"}}
                                />
                            : <MaterialIcon
                                icon="assessment"
                                color="black"
                                style={{"padding-right": "18px"}}
                            />}
                            <Typography>{ isChartView ? "Water Level Chart" : "Water Level Table" }</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Grid container spacing={24}>
                                <Grid item xs={10}></Grid>
                                <Grid item xs={2}>
                                    <Paper className={classes.paper} style={{"background-color":"#f0f0f0", }} >
                                        <Button onClick={!isChartView ? this.toggleChart.bind(this) : this.doNone.bind(this)}>
                                            <MaterialIcon
                                                icon="assessment"
                                                color="blue"
                                                style={{"float":"left"}}
                                            />
                                        </Button>
                                        <Button onClick={!isChartView ? this.doNone.bind(this) : this.toggleChart.bind(this)}>
                                            <MaterialIcon
                                                icon="list"
                                                color="blue"
                                                style={{"float":"left"}}
                                            />
                                        </Button>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    { isChartView ? 
                                        <Grid container spacing={12}>
                                            <Grid item xs={12}>
                                                <TimeSeriesChart
                                                    data={welltimes}
                                                    plots={this.state.plots}
                                                    waterdepth={this.state.waterdepth}
                                                    groundwaterelev={this.state.groundwaterelev}
                                                    measurement={this.state.measurement}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>                            
                                                <ChartFilter
                                                    handleChange={this.handleChange.bind(this)}
                                                    Fall={this.state.Fall}
                                                    Spring={this.state.Spring}
                                                    All={this.state.All}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <ChartToggle
                                                    handleChange={this.handleChange.bind(this)}
                                                    waterdepth={this.state.waterdepth}
                                                    groundwaterelev={this.state.groundwaterelev}
                                                    measurement={this.state.measurement}
                                                />
                                            </Grid>
                                        </Grid>
                                    :
                                        welltimes[0] === undefined ? 
                                            <Grid item xs={12}><TimeSeriesTable /></Grid>
                                        :
                                            <Grid item xs={12}><TimeSeriesTable
                                                fieldconf={welltimesconfig}
                                                data={welltimes} />
                                            </Grid>
                                    }
                                </Grid>
                            </Grid>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    
                    <ExpansionPanel className={classes.panel}>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            <svg className={classes.svgStyle} viewBox="0 0 24 24">
                                <path fill="#000000" d="M3,21V17.29L10.78,12.8L14.55,15L21,11.25V21H3M21,8.94L14.55,12.67L10.78,10.5L3,15V12.79L10.78,8.3L14.55,10.5L21,6.75V8.94Z" />
                            </svg>
                            <Typography>Current Reference Elevation</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Grid container spacing={12}>
                                {/* The Well Reference here should be the most recent reading of an array of readings. Needs a toggleable table view with a selector to edit individual rows. */}
                                <Grid item xs={12}>
                                    <b>Current Reference Elevation</b>
                                    <FlexibleForm
                                        fieldconf={WellRefConfig}
                                        fields={wellRef}
                                        saveFlexible={this.saveWellRef.bind(this)}
                                        handleChange={this.handleChange.bind(this)}
                                        hasMap={false}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <b>View/Edit Historic Elevations</b>
                                    <TimeSeriesTable
                                        fieldconf={WellRefConfig}
                                        data={[wellRef]} />
                                </Grid>
                            </Grid>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel className={classes.panel}>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            <ListItemIcon className={classes.pageText}>
                                <svg className={classes.svgStyle} style={{"paddingRight":"0px"}} viewBox="0 0 24 24">
                                    <path fill="black" d="M19,14.5C19,14.5 21,16.67 21,18A2,2 0 0,1 19,20A2,2 0 0,1 17,18C17,16.67 19,14.5 19,14.5M5,18V9A2,2 0 0,1 3,7A2,2 0 0,1 5,5V4A2,2 0 0,1 7,2H9A2,2 0 0,1 11,4V5H19A2,2 0 0,1 21,7V9L21,11A1,1 0 0,1 22,12A1,1 0 0,1 21,13H17A1,1 0 0,1 16,12A1,1 0 0,1 17,11V9H11V18H12A2,2 0 0,1 14,20V22H2V20A2,2 0 0,1 4,18H5Z" />
                                </svg>
                            </ListItemIcon>
                            <Typography>Water Volume Pumping</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <h2>Water Volume Pumping</h2>
                            <WaterVolume />
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel className={classes.panel}>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            <ListItemIcon className={classes.pageText}>
                                <Check className={classes.iconSize} />
                            </ListItemIcon>
                            <Typography>Water Quality</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <h2>Water Quality</h2>
                            <WaterQuality />
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel className={classes.panel}>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            <ListItemIcon className={classes.pageText}>
                                <Waves className={classes.iconSize} />
                            </ListItemIcon>
                            <Typography>Pump Tests</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <h2>Pump Tests</h2>
                            <PumpTests />
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel className={classes.panel}>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            <ListItemIcon className={classes.pageText}>
                                <ViewList className={classes.iconSize} />
                            </ListItemIcon>
                            <Typography>Maintenance Log</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <h2>Maintenance Log</h2>
                            <MaintenanceLog />
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    {/*
                    <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            <MaterialIcon
                                icon="map"
                                color="black"
                                style={{"padding-right":"24px"}}
                            />
                            <Typography>Map</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    */}
                </AppContainer>
                
            );
        }
    }
}
WellDetail = connect(
    (state, ownProps) => ({
        //navState: state.nav,
        authState: state.auth,
        well: getWell(state, ownProps),
        wellRef: getWellRef(state, ownProps),
        //wellResults: getWellResults(state, ownProps),
    }),
    {
        ...Well.actions,
        ...WellTimeModel.actions,
        ...WellParameter.actions,
        ...WellCustom.actions,
        ...authActions,
        ...navActions,
    }
)(WellDetail);

export default compose(withStyles(styles))(WellDetail); //withStyles(styles)

import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
//import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import TableCell from "@material-ui/core/TableCell"; //"./TableCell";

const styles = {
    centerAlign: {
        textAlign: "center"
    },
    ts_hidden: {
        display: 'none'
    },
    ts_shown: {
        display: 'table-cell'
    },
    tableCell: {
        padding: "4px 24px",
    }
};

class EnhancedTableHead extends Component {
    createSortHandler = property => event => {
        this.props.onRequestSort(event, property);
    };
    render() {
        const { order, classes, orderBy, columnData } = this.props;
        
        return (
            <TableHead>
                <TableRow>
                    {columnData.map(column => {
                        return (
                            <TableCell
                                style={{padding: "4px 0px"}}
                                key={column.id}
                                sortDirection={orderBy === column.id ? order : false}
                                className={ column.hidden ? classes.ts_hidden : classes.ts_shown }>
                            
                                {column.allowSort && <TableSortLabel
                                            active={orderBy === column.id}
                                            direction={order}
                                            onClick={this.createSortHandler(column.id)}>
                                            <Typography>{column.label}</Typography>
                                        </TableSortLabel>}
                                {!column.allowSort && <Typography>{column.label}</Typography>}
                            </TableCell>
                        );
                    }, this)}
                </TableRow>
            </TableHead>
        );
    }
}

export default withStyles(styles)(EnhancedTableHead);

import React from "react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
//import { CircularProgress } from "@material-ui/core/Progress";
import { withStyles } from "@material-ui/core/styles";

const styles = {
    submitWrapper: {
        position: "relative"
    },
    progress: {
        top: "50%",
        left: "50%",
        color: "#4CAF50",
        position: "absolute",
        "margin-top": -12,
        "margin-left": -12
    }
};

const Submit = ({ label, subLabel, altAction, altLabel, classes, loading, gridSize = 12, justify = "center", greyButton = false, altAction2, altLabel2}) => (
    <Grid container justify={justify}  style={{"width":"100%", "margin-right":"0", "margin-left":"0"}} >
        {altAction ? (
            <Grid item xs={gridSize / 2}>
                <Button fullWidth component={Link} to={altAction}>
                    {altLabel}
                </Button>
            </Grid>
        ) : (
            <div />
        )}
        <Grid item xs={gridSize / 2}>
            <div className={classes.submitWrapper}>
                <Button fullWidth type="submit" variant="raised" color="primary" disabled={loading}>
                    {label} {subLabel}
                </Button>
            </div>
        </Grid>
        {altAction2 ? (
            <Grid item xs={gridSize / 2}>
                <Button fullWidth component={Link} to={altAction2}>
                    {altLabel2}
                </Button>
            </Grid>
        ) : ( <div></div> )}
    </Grid>
);

/*
 *
 * <Grid container justify={justify}>
        {altAction ? [
            (greyButton
                ? 
                <Grid item xs={gridSize / 2}>
                    <Button fullWidth component={Link} to={altAction} variant="raised" color="grey">
                        {altLabel}
                    </Button>
                </Grid>
             :
                <Grid item xs={gridSize / 2}>
                    <Button fullWidth component={Link} to={altAction} variant="raised" color="primary">
                        {altLabel}
                    </Button>
                </Grid>
            )
        ] : (
            <div />
        )}
        <Grid item xs={gridSize / 2}>
            <div className={classes.submitWrapper}>
                <Button fullWidth type="submit" variant="raised" color="primary" disabled={loading}>
                    {label} {subLabel}
                </Button>
                
            </div>
        </Grid>        
    </Grid>
    */
//{loading && <CircularProgress size={24} className={classes.progress} />}
export default withStyles(styles)(Submit);

import React, {
  Children,
  cloneElement,
  type ChildrenArray,
  } from 'react';
import { Control, type Layer }  from 'leaflet';
import LegendLayers from '../common/LegendLayers';

import { type MapControlProps, MapControl, withLeaflet, ControlledLayer } from 'react-leaflet';


class BaseLayer extends ControlledLayer {
  constructor(props: ControlledLayerProps) {
    super(props)
    this.contextValue = {
      ...props.leaflet,
      layerContainer: {
        addLayer: this.addLayer.bind(this),
        removeLayer: this.removeLayer.bind(this),
      },
    }
  }

  addLayer = (layer: Layer) => {
    this.layer = layer // Keep layer reference to handle dynamic changes of props
    const { addBaseLayer, checked, name } = this.props
    addBaseLayer(layer, name, checked)
  }
}
class Overlay extends ControlledLayer {
  constructor(props: ControlledLayerProps) {
    super(props)
    this.contextValue = {
      ...props.leaflet,
      layerContainer: {
        addLayer: this.addLayer.bind(this),
        removeLayer: this.removeLayer.bind(this),
      },
    }
  }

  addLayer = (layer: Layer) => {
    this.layer = layer // Keep layer reference to handle dynamic changes of props
    const { addOverlay, checked, name } = this.props
    addOverlay(layer, name, checked)
  }
}

type LeafletElement = Control.Layers;

type LayersControlProps = {
  children: ChildrenArray<*>,
  collapsed?: boolean,
} & MapControlProps;

class CLayersControl extends MapControl<LeafletElement, LayersControlProps> {
   controlProps: {
    addBaseLayer: AddLayerHandler,
    addOverlay: AddLayerHandler,
    removeLayer: RemoveLayerHandler,
    removeLayerControl: RemoveLayerHandler,
  }

  constructor(props: LayersControlProps) {
    super(props)
    this.controlProps = {
      addBaseLayer: this.addBaseLayer.bind(this),
      addOverlay: this.addOverlay.bind(this),
      leaflet: props.leaflet,
      removeLayer: this.removeLayer.bind(this),
      removeLayerControl: this.removeLayerControl.bind(this),
    }
  }

  createLeafletElement(props: LayersControlProps): LeafletElement {
    const { children: _children, ...options } = props
    return new LegendLayers(undefined, undefined, options);
  }

  updateLeafletElement(
    fromProps: LayersControlProps,
    toProps: LayersControlProps,
  ) {
    super.updateLeafletElement(fromProps, toProps)
    if (toProps.collapsed !== fromProps.collapsed) {
      if (toProps.collapsed === true) {
        this.leafletElement.collapse()
      } else {
        this.leafletElement.expand()
      }
    }
  }

  componentWillUnmount() {
    setTimeout(() => {
      super.componentWillUnmount()
    }, 0)
  }

  addBaseLayer(layer: Layer, name: string, checked: boolean = false) {
    if (checked && this.props.leaflet.map != null) {
      this.props.leaflet.map.addLayer(layer)
    }
    this.leafletElement.addBaseLayer(layer, name)
  }

  addOverlay(layer: Layer, name: string, checked: boolean = false) {
    if (checked && this.props.leaflet.map != null) {
      this.props.leaflet.map.addLayer(layer)
    }
    this.leafletElement.addOverlay(layer, name)
  }

  removeLayer(layer: Layer) {
    if (this.props.leaflet.map != null) {
      this.props.leaflet.map.removeLayer(layer)
    }
  }

  removeLayerControl(layer: Layer) {
    this.leafletElement.removeLayer(layer)
  }

  render() {
    const children = Children.map(this.props.children, child => {
      return child ? cloneElement(child, this.controlProps) : null
    })
    
    return <>{children}</>
  }
}
const CollapsibleLayersControl: Object = withLeaflet(CLayersControl)

CollapsibleLayersControl.BaseLayer = BaseLayer
CollapsibleLayersControl.Overlay = Overlay

export default CollapsibleLayersControl;
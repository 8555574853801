import React, { Component } from "react";
import Switch from '@material-ui/core/Switch';
//import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";

export class ChartFilter extends Component {
    render() {
        const { handleChange } = this.props;
        return (
            <Grid container spacing={12}>
                <Grid item xs>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={this.props.Fall}
                                onChange={handleChange('Fall')}
                                value="Fall"
                            />
                        }
                        label="Show Fall Results Only"
                    />
                </Grid>
                <Grid item xs>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={this.props.Spring}
                                onChange={handleChange('Spring')}
                                value="Spring"
                            />
                        }
                        label="Show Spring Results Only"
                    />
                </Grid>
                <Grid item xs>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={this.props.All}
                                onChange={handleChange('All')}
                                value="All"
                            />
                        }
                        label="Show All Results"
                    />
                </Grid>            
            </Grid>
        );
    }
};

export class ChartToggle extends Component {
    render() {
        const { handleChange } = this.props;
        return (
            <Grid container spacing={12}>
                <Grid item xs>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={this.props.waterdepth}
                                onChange={handleChange('waterdepth')}
                                value="Water Depth"
                            />
                        }
                        label="Toggle Water Depth"
                    />
                </Grid>
                <Grid item xs>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={this.props.groundwaterelev}
                                onChange={handleChange('groundwaterelev')}
                                value="Groundwater Elevation"
                            />
                        }
                        label="Toggle Groundwater Elevation"
                    />
                </Grid>
                <Grid item xs>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={this.props.measurement}
                                onChange={handleChange('measurement')}
                                value="Measurement"
                            />
                        }
                        label="Toggle Measurement"
                    />
                </Grid>           
            </Grid>
        );
    }
};

import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";

import { withStyles } from "@material-ui/core/styles";

import Drawer from "@material-ui/core/Drawer";
import Typography from "@material-ui/core/Typography";

const styles = {
    linkColor: {
        color: "#fff",
        cursor: "pointer",
        textDecoration: "none",
        "&:hover": {
            textDecoration: "underline"
        }
    },
    rootText: {
        background: "#333333",
        textAlign: "center",
        color: "#fff",
        padding: "8px 0",
        fontWeight: 200
    },
    paddingRight: {
        paddingRight: "79px",
        float: "right"
    },
    paddingLeft: {
        paddingLeft: "79px",
        float: "left"
    },
    marginLeft: {
        paddingLeft: "10px"
    },
    marginRight: {
        paddingRight: "10px"
    },
    border: {
        border: "none"
    }
};

class Footer extends Component {
    render() {
        const { classes } = this.props;

        return (
            <Drawer anchor="bottom" variant="permanent" classes={{ paper: classes.border }}>
                <Typography variant="caption" className="tclass">
                    <Grid container>
                        <Grid item xs={12}>
                            <span className={classes.paddingLeft}>
                                
                            </span>
                            <span className={classes.paddingRight}>
                                V 1.0.0 * Built by <a href="www.heigeo.com">heigeo</a>
                            </span>
                        </Grid>
                    </Grid>
                    
                </Typography>
            </Drawer>
        );
    }
}
/*<Terms dialogOpen={termsOpen} closeEvent={this.closeTerms} />
                    <Privacy dialogOpen={privacyOpen} closeEvent={this.closePrivacy} />*/
export default withStyles(styles)(Footer);

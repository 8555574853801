import { reloadAll } from "../common/orm";
import { RESET_STATE } from "@redux-offline/redux-offline/lib/constants";

export const AUTH_LOGIN_PENDING = "AUTH_LOGIN_PENDING";
export const AUTH_LOGIN_SUCCESS = "AUTH_LOGIN_SUCCESS";
export const AUTH_LOGIN_ERROR = "AUTH_LOGIN_ERROR";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_REGISTER_PENDING = "AUTH_REGISTER_PENDING";
export const AUTH_REGISTER_SUCCESS = "AUTH_REGISTER_SUCCESS";
export const AUTH_REGISTER_ERROR = "AUTH_REGISTER_ERROR";
export const AUTH_REVALIDATE_PENDING = "AUTH_REVALIDATE_PENDING";
export const AUTH_REVALIDATE_SUCCESS = "AUTH_REVALIDATE_SUCCESS";
export const AUTH_REVALIDATE_ERROR = "AUTH_REVALIDATE_ERROR";
export const AUTH_UPDATE_PENDING = "AUTH_UPDATE_PENDING";
export const AUTH_UPDATE_SUCCESS = "AUTH_UPDATE_SUCCESS";
export const AUTH_UPDATE_ERROR = "AUTH_UPDATE_ERROR";
export const AUTH_PASSWORD_RESET_PENDING = "AUTH_PASSWORD_RESET_PENDING";
export const AUTH_PASSWORD_RESET_SUCCESS = "AUTH_PASSWORD_RESET_SUCCESS";
export const AUTH_PASSWORD_RESET_ERROR = "AUTH_PASSWORD_RESET_ERROR";
export const AUTH_PASSWORD_RECOVER_PENDING = "AUTH_PASSWORD_RECOVER_PENDING";
export const AUTH_PASSWORD_RECOVER_SUCCESS = "AUTH_PASSWORD_RECOVER_SUCCESS";
export const AUTH_PASSWORD_RECOVER_ERROR = "AUTH_PASSWORD_RECOVER_ERROR";
export const AUTH_PASSWORD_CHANGE_PENDING = "AUTH_PASSWORD_CHANGE_PENDING";
export const AUTH_PASSWORD_CHANGE_SUCCESS = "AUTH_PASSWORD_CHANGE_SUCCESS";
export const AUTH_PASSWORD_CHANGE_ERROR = "AUTH_PASSWORD_CHANGE_ERROR";

function isSuccess(response) {
    return response.status >= 200 && response.status < 300;
}

export function authLoginPending() {
    return {
        type: AUTH_LOGIN_PENDING
    };
}

export function authLoginSuccess(userInfo) {
    return {
        type: AUTH_LOGIN_SUCCESS,
        user: userInfo
    };
}

export function authLoginError(error) {
    return {
        type: AUTH_LOGIN_ERROR,
        error: error
    };
}

export function authLogin(values) {
    return function(dispatch) {
        dispatch(authLoginPending());
        return fetch("/auth/token/create/", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                username: values.username,
                password: values.password
            })
        }).then(function(response) {
            if (isSuccess(response)) {
                return response.json().then(data => {
                    dispatch(authLoginSuccess(data));
                    dispatch(reloadAll());
                });
            } else {
                return dispatch(authLoginError("Invalid Username/Password"));
            }
        });
    };
}

export function authLogout() {
    return function(dispatch, getState) {
        const { auth, offline } = getState();
        // Warn user when logging out with outbox data
	        if (offline.outbox.length > 0) {
	            if (
	                window.confirm(
	                    "You currently have unsaved data waiting to sync to the server. If you continue this data will be lost. Do you wish to proceed?"
	                )
	            ) {
	                // Clear outbox
	                dispatch({ type: RESET_STATE });
	            } else {
	                // Cancel
	                return;
	            }
	        }
        if (auth && auth.user) {
            fetch("/auth/token/destroy/", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + auth.user.auth_token
                }
            });
        }
        // Don't need to wait for response
        dispatch({
            type: AUTH_LOGOUT
        });
        //dispatch(reloadAll());
    };
}

export function authRevalidatePending() {
    return {
        type: AUTH_REVALIDATE_PENDING
    };
}

export function authRevalidateSuccess() {
    return {
        type: AUTH_REVALIDATE_SUCCESS
    };
}

export function authRevalidateError(error) {
    return {
        type: AUTH_REVALIDATE_ERROR,
        error: error
    };
}

export function authUpdatePending() {
    return {
        type: AUTH_UPDATE_PENDING
    };
}

export function authUpdateSuccess(userInfo) {
    return {
        type: AUTH_UPDATE_SUCCESS,
        user: userInfo
    };
}

export function authUpdateError(error) {
    return {
        type: AUTH_UPDATE_ERROR,
        error: error
    };
}

/* I don't think we'll need this. And we don't have an /auth/me endpoint */
export function authUpdate(values) {
    return function(dispatch, getState) {
        var { auth } = getState();

        if (auth && auth.user) {
            dispatch(authUpdatePending());
            return fetch("/auth/me/", {
                method: "PUT",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + auth.user.auth_token
                },
                body: JSON.stringify(values)
            }).then(function(response) {
                if (isSuccess(response)) {
                    return response.json().then(data => {
                        dispatch(authUpdateSuccess(data));
                    });
                } else if (response.status === 400) {
                    return response.json().then(data => {
                        dispatch(authUpdateError(data));
                    });
                } else {
                    dispatch(authUpdateError({ error: "Unknown Server Error" }));
                }
            });
        }
    };
}

export function authRegisterPending() {
    return {
        type: AUTH_REGISTER_PENDING
    };
}

export function authRegisterSuccess() {
    return {
        type: AUTH_REGISTER_SUCCESS
    };
}

export function authRegisterError(error) {
    return {
        type: AUTH_REGISTER_ERROR,
        error: error
    };
}

export function authRegister(values) {
    return function(dispatch) {
        dispatch(authRegisterPending());
        return fetch("/auth/users/create/", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(values)
        }).then(response => {
            if (isSuccess(response)) {
                dispatch(authRegisterSuccess());
            } else if (response.status === 400) {
                return response.json().then(data => {
                    dispatch(authRegisterError(data));
                });
            } else {
                dispatch(authRegisterError({ error: "Unknown Server Error" }));
            }
        });
    };
}

export function authPasswordResetPending() {
    return {
        type: AUTH_PASSWORD_RESET_PENDING
    };
}

export function authPasswordResetSuccess(data) {
    return {
        type: AUTH_PASSWORD_RESET_SUCCESS,
        data: data
    };
}

export function authPasswordResetError(error) {
    return {
        type: AUTH_PASSWORD_RESET_ERROR,
        error: error
    };
}

export function authPasswordReset(values) {
    return function(dispatch) {
        dispatch(authPasswordResetPending());
        return fetch("/auth/password/reset/", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                username: values.username
            })
        }).then(response => {
            if (isSuccess(response)) {
                dispatch(
                    authPasswordResetSuccess({
                        username: values.username
                    })
                );
            } else {
                dispatch(authPasswordResetError("Unknown Error. Please try again."));
            }
        });
    };
}

export function authPasswordRecoverPending() {
    return {
        type: AUTH_PASSWORD_RECOVER_PENDING
    };
}

export function authPasswordRecoverSuccess() {
    return {
        type: AUTH_PASSWORD_RECOVER_SUCCESS
    };
}

export function authPasswordRecoverError(error) {
    return {
        type: AUTH_PASSWORD_RECOVER_ERROR,
        error: error
    };
}

export function authPasswordRecover(values) {
    return function(dispatch) {
        dispatch(authPasswordRecoverPending());
        return fetch("/auth/password/reset/confirm/", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(values)
        }).then(response => {
            if (isSuccess(response)) {
                dispatch(authPasswordRecoverSuccess());
            } else if (response.status === 400) {
                return response.json().then(data => {
                    dispatch(authPasswordRecoverError(data));
                });
            } else {
                dispatch(authPasswordRecoverError({ error: "Unknown Server Error" }));
            }
        });
    };
}


export function authPasswordChangePending() {
    return {
        type: AUTH_PASSWORD_CHANGE_PENDING
    };
}

export function authPasswordChangeSuccess() {
    return {
        type: AUTH_PASSWORD_CHANGE_SUCCESS
    };
}

export function authPasswordChangeError(error) {
    return {
        type: AUTH_PASSWORD_CHANGE_ERROR,
        error: error
    };
}

export function authPasswordChange(values) {
    return function(dispatch, getState) {
        var { auth } = getState();

        if (auth && auth.user ) {
            dispatch(authPasswordChangePending());
            return fetch("/auth/password/", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + auth.user.auth_token
                },
                body: JSON.stringify(values)
            }).then(response => {
                if (isSuccess(response)) {
                    dispatch(authPasswordChangeSuccess());
                } else if (response.status === 400) {
                    return response.json().then(data => {
                        dispatch(authPasswordChangeError(data));
                    });
                } else {
                    dispatch(authPasswordChangeError({ error: "Unknown Server Error" }));
                }
            });
        }
    };
}

import {
    NAV_TOGGLE_NAVIGATION,
    NAV_TOGGLE_WELL_LIBRARY,
    NAV_TOGGLE_SUPPORT,
    NAV_TOGGLE_PROJECT,
    NAV_SELECT_PAGE,
    WELL_SELECTED,
    WELLTIME_SELECTED,
    FILTER_WELL,
    DASHBOARD_SELECTOR,
    SW_CHECKING,
    SW_REGISTERED,
    SW_INSTALLED,
    SW_UPDATE,
    SW_ERROR,
    SW_DISABLED,
    STORE_READY
} from "./actions";

/*
 * NEXT UP!
 * The actions.js sends the "type" to the reducer.
 * The reducer sets the global state for the component.
 *
 * We'll need to set up the well component through the store so we don't have to keep loading the well through the interface. 
 *
 */

function navReducer(state = {}, action) {
    switch (action.type) {
        case NAV_TOGGLE_NAVIGATION:
            // If closing the navigation panel then also collapse the main menus
            // Don't worry about sub-menus - it is better to retain their status
            if (state.navigationOpen) {
                return {
                    ...state,
                    navigationOpen: !state.navigationOpen,
                    libraryOpen: false,
                    supportOpen: false,
                    projectOpen: false,
                    libraryOpenPrevious: state.libraryOpen,
                    supportOpenPrevious: state.supportOpen,
                    projectOpenPrevious: state.projectOpen
                };
            } else {
                // If they have a previous state re-open to that
                if ("libraryOpenPrevious" in state) {
                    return {
                        ...state,
                        navigationOpen: !state.navigationOpen,
                        libraryOpen: state.libraryOpenPrevious,
                        supportOpen: state.supportOpenPrevious,
                        projectOpen: state.projectOpenPrevious
                    };
                } else {
                    return {
                        ...state,
                        libraryOpen: true,
                        navigationOpen: !state.navigationOpen
                    };
                }
            }
        case NAV_TOGGLE_WELL_LIBRARY:
            // If navigation is collapsed then reopen
            if (!state.navigationOpen) {
                return {
                    ...state,
                    navigationOpen: !state.navigationOpen,
                    libraryOpen: true
                };
            } else {
                return {
                    ...state,
                    libraryOpen: !state.libraryOpen
                };
            }

        case WELL_SELECTED:
            if (action.well_id !== undefined && action.id !== undefined) {
                return {
                    ...state,
                    well_id: action.well_id,
                    id: action.id,
                    wellSelected: true
                };
            } else {
                return {
                    ...state,
                    well_id: "No",
                    id: "",
                    wellSelected: false
                };
            }
            
        case WELLTIME_SELECTED:
            if (action.welltime_id !== undefined && action.welltime_id !== undefined) {
                return {
                    ...state,
                    welltime_id: action.welltime_id,
                };
            } else {
                return {
                    ...state,
                    welltime_id: "No",
                };
            }
        case FILTER_WELL:
            return {
                ...state,
                filterwell: !state.filterwell
            };
        case NAV_TOGGLE_SUPPORT:
            // If navigation is collapsed then reopen
            if (!state.navigationOpen) {
                return {
                    ...state,
                    navigationOpen: !state.navigationOpen,
                    supportOpen: true
                };
            } else {
                return {
                    ...state,
                    supportOpen: !state.supportOpen
                };
            }

        case NAV_TOGGLE_PROJECT:
            // If navigation is collapsed then reopen
            if (!state.navigationOpen) {
                return {
                    ...state,
                    navigationOpen: !state.navigationOpen,
                    projectOpen: true
                };
            } else {
                return {
                    ...state,
                    projectOpen: !state.projectOpen
                };
            }

        case DASHBOARD_SELECTOR:
            return {
                ...state,
                selectedButton: action.button
            };

        case NAV_SELECT_PAGE:
            return {
                ...state,
                selectedPage: action.page
            };

        case SW_CHECKING:
            return {
                ...state,
                sw: { checking: true }
            };

        case SW_REGISTERED:
            return {
                ...state,
                sw: { registered: true }
            };

        case SW_INSTALLED:
            return {
                ...state,
                sw: { installed: true }
            };

        case SW_UPDATE:
            return {
                ...state,
                sw: { update: true }
            };

        case SW_ERROR:
            return {
                ...state,
                sw: { error: true }
            };

        case SW_DISABLED:
            return {
                ...state,
                sw: null
            };

        case STORE_READY:
            return {
                ...state,
                storeReady: true
            };

        default:
            return state;
    }
}

export default navReducer;

import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import classNames from "classnames";
import Drawer from "@material-ui/core/Drawer";
import ListItem from "@material-ui/core/ListItem"; //ListItemIcon, List,
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import MaterialIcon from 'material-icons-react';

//import { LinearProgress } from "@material-ui/core/LinearProgress";
//import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core";
import WellNavigation from "./WellNavigation";
import List from "@material-ui/icons/List";
//import Map from "@material-ui/icons/Map";
//import AddBox from "@material-ui/icons/AddBox";    // For Create New Well
//import Waves from "@material-ui/icons/Waves"; // For Water Level
//import Collapse from "@material-ui/core/Collapse";
//import ChevronLeftIcon from "material-ui-icons/ChevronLeft";
//import ChevronRightIcon from "material-ui-icons/ChevronRight";
//import ExpandLess from "material-ui-icons/ExpandLess";
//import ExpandMore from "material-ui-icons/ExpandMore";
//import DashboardIcon from "./icons/DashboardIcon";
//import AddNewIcon from "./icons/AddNewIcon";
import * as navActions from "./actions";

const DEFAULT_WELL = {
    "well_id": "Current Well",
    "id": "new"
}

const styles = theme => ({
    drawerPaper: {
        height: "100%", //calc(100% - 100px)", // 64 = height of Header + 32 height of Footer
        marginTop: 50, // 64 = height of Header
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        }),
        overflowX: "hidden",
        width: theme.spacing.unit * 7,
        whiteSpace: "nowrap",
        backgroundColor: "#242424"
    },
    drawerOpen: {
        [theme.breakpoints.up("lg")]: {
            width: 325
        },
        [theme.breakpoints.down("md")]: {
            width: 275
        },
        "& $listItemSecond": {
            marginLeft: theme.spacing.unit * 3,
            paddingRight: theme.spacing.unit * 4
        },
        "& $listItemThird": {
            marginLeft: theme.spacing.unit * 6,
            paddingRight: theme.spacing.unit * 7
        },
        "& $overflow": {
            overflowY: "auto"
        }
    },
    selectedPage: {
        backgroundColor: "#4e4e4e"
    },
    pageText: {
        color: "#aaaaaa !important"
    },
    selectedPageText: {
        color: "#aaaaaa !important"
    },
    topPageText: {
        color: "#ffffff"
    },
    listItem: {
        paddingLeft: theme.spacing.unit * 2,
        paddingRight: theme.spacing.unit * 2,
        "&:hover": {
            backgroundColor: "#4e4e4e !important"
        }
    },
    listItemDashboard: {
        borderBottom: "1px solid #00adee"
    },
    listItemField: {
        borderBottom: "1px solid #34a853"
    },
    listItemCrop: {
        borderBottom: "1px solid #7ac143"
    },
    listItemProject: {
        borderBottom: "1px solid #ff7d32"
    },
    listItemSupport: {
        borderBottom: "1px solid #aaaaaa"
    },
    listItemDashboardSelected: {
        borderBottom: "3px solid #00adee"
    },
    listItemCropSelected: {
        borderBottom: "3px solid #7ac143"
    },
    listItemProjectSelected: {
        borderBottom: "3px solid #ff7d32"
    },
    listItemSupportSelected: {
        borderBottom: "3px solid #aaaaaa"
    },
    listItemSecond: {
        transition: theme.transitions.create("margin-left", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    listItemThird: {
        transition: theme.transitions.create("margin-left", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    overflow: {
        overflowY: "hidden"
    },
    noFlex: {
        padding: 0
    },
    iconSize: {
        flexShrink: 0,
        width: "1em",
        height: "1em",
        fontSize: "24px"
    },
    iconSizeSmaller: {
        flexShrink: 0,
        width: ".8em",
        height: ".8em",
        fontSize: "24px"
    },
    farmHalf: {
        width: "50%",
        display: "inline-block",
        height: 48
    },
    farmHalfText: {
        display: "inline-block",
        verticalAlign: "top",
        maxWidth: "110%"
    },
    farmHalfLarge: {
        [theme.breakpoints.up("lg")]: {
            width: "74%"
        },
        [theme.breakpoints.down("md")]: {
            width: "68%"
        }
    },
    supportHalfLarge: {
        [theme.breakpoints.up("lg")]: {
            width: "82%"
        },
        [theme.breakpoints.down("md")]: {
            width: "76%"
        }
    },
    textOverflow: {
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    textOverflowField: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: "110%"
    },
    hidden: {
        display: 'none',
    }
});

class Navigation extends React.Component {
    continueDashboard = () => {
        this.props.navSelectPage("dashboard");
        this.props.history.push("/dashboard");
    };
    navigationClick = () => {
        this.props.navToggleNavigation();
    };
    wellLibraryClick = () => {
        this.props.navToggleWellLibrary();
    };
    supportClick = () => {
        this.props.navToggleSupport();
    };
    projectClick = () => {
        this.props.navToggleProject();
    };
    renderThumb({ style, ...props }) {
        const thumbStyle = {
            backgroundColor: "#f15d22"
        };
        return <div style={{ ...style, ...thumbStyle }} />;
    }
    constructor(props) {
        super(props);
        this.state = { ...DEFAULT_WELL };
    }
    
    render() {
        var {
            classes,
            //item={well_id:""},
            //well_id='',
            //authState,
            navState,
            navSelectPage,
            //navToggleWellLibrary
            //history
        } = this.props;
        // Initial state in index no longer working?
        if (!navState.navigationOpen && navState.navigationOpen !== false) {
            this.props.navToggleNavigation();
        } 
        // {/*style={{ "margin-top": 100 }}*/}
        return (
            <Drawer
                id="drawContainer"
                variant="permanent"
                open={true}
                classes={{
                    paper: classNames(classes.drawerPaper, navState.navigationOpen && classes.drawerOpen)
                }}>
                <Scrollbars
                    renderThumbVertical={this.renderThumb}
                    autoHide
                    autoHideTimeout={1000}
                    autoHideDuration={200}>
                    <div>
                        <div>
                            <ListItem
                                title="Dashboard Map"
                                button
                                component={Link}
                                to="/wellsmap"
                                onClick={() => navSelectPage("wellsmap")}
                                className={classNames(
                                    classes.listItem,
                                )}>
                                <ListItemIcon className={classes.pageText}>
                                    <MaterialIcon icon="dashboard" color="white" />
                                </ListItemIcon>
                                <ListItemText
                                    inset
                                    primary="Dashboard Map"
                                    classes={{
                                        primary: classes.topPageText
                                    }}
                                />
                            </ListItem>
                            <ListItem
                                title="Groundwater Wells"
                                button
                                component={Link}
                                to="/wellslist"
                                onClick={() => navSelectPage("wellslist")}
                                className={classNames(
                                    classes.listItem,
                                )}>
                                <ListItemIcon className={classes.pageText}>
                                    <List className={classes.iconSize} />
                                </ListItemIcon>
                                <ListItemText
                                    inset
                                    primary="Groundwater Wells"
                                    classes={{
                                        primary: classes.topPageText
                                    }}
                                />
                            </ListItem>
                            
                            {/*<ListItem
                                title="Create New Well"
                                button
                                component={Link}
                                to="/wellcreate"
                                onClick={() => navSelectPage("wellcreate")}
                                className={classNames(
                                    classes.listItem,
                                )}>
                                <ListItemIcon className={classes.pageText}>
                                    <AddBox className={classes.iconSize} />
                                </ListItemIcon>
                                <ListItemText
                                    inset
                                    primary="Create New Well"
                                    classes={{
                                        primary: classes.topPageText
                                    }}
                                />
                            </ListItem>*/}
                            <WellNavigation />
                            <ListItem
                                title="Collapse Panel"
                                button
                                onClick={this.navigationClick}
                                className={classes.listItem}>
                                <ListItemIcon className={classes.pageText}>
                                <div className={navState.navigationOpen ? classes.hidden : '' }>
                                    <MaterialIcon
                                        color='blue'
                                        icon='keyboard_arrow_right'
                                    />
                                </div>
                                <div className={navState.navigationOpen ? '' : classes.hidden}>
                                    <MaterialIcon
                                        color='green'
                                        icon='keyboard_arrow_left'
                                    />
                                </div>
                                </ListItemIcon>
                                <ListItemText
                                    inset
                                    primary="Collapse Panel"
                                    classes={{
                                        primary: classes.topPageText
                                    }}
                                />
                            </ListItem>
                        </div>
                    </div>
                </Scrollbars>
            </Drawer>
        );
    }
}
/*
 *{navState.navigationOpen ?
                                    <ListItemIcon className={classes.pageText}>
                                         <MaterialIcon icon="keyboard_arrow_left" color="white" />
                                    </ListItemIcon>
                                :   <ListItemIcon className={classes.pageText}>
                                         <MaterialIcon icon="keyboard_arrow_right" color="white" />
                                    </ListItemIcon>
                                }*/
Navigation = connect(
    state => ({
        navState: state.nav,
        authState: state.auth,
        syncState: state.sync
    }),
    {
        ...navActions
    }
)(Navigation);
export default withStyles(styles)(withRouter(Navigation));
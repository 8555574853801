import React, { Component } from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, } from 'recharts';
/*
const data = [
  {
    name: 'Page A', uv: 4000, pv: 2400, amt: 2400,
  },
  {
    name: 'Page B', uv: 3000, pv: 1398, amt: 2210,
  },
  {
    name: 'Page C', uv: 2000, pv: 9800, amt: 2290,
  },
  {
    name: 'Page D', uv: 2780, pv: 3908, amt: 2000,
  },
  {
    name: 'Page E', uv: 1890, pv: 4800, amt: 2181,
  },
  {
    name: 'Page F', uv: 2390, pv: 3800, amt: 2500,
  },
  {
    name: 'Page G', uv: 3490, pv: 4300, amt: 2100,
  },
];
const defaultPlots = [
  {
    key: 'waterdepth',
    color: '#8884d8'
  },
  {
    key: 'groundwaterelev',
    color: '#82ca9d'
  }
];
*/
//                <Line type="monotone" dataKey="groundwaterelev" stroke="#82ca9d" />


export default class TimeSeriesChart extends Component {
    render() {
        const { data, waterdepth, groundwaterelev, measurement } = this.props; //plots=defaultPlots, 
        return (
            <LineChart width={800} height={400} data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis reversed="true" dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                {waterdepth ? 
                  <Line
                    type="monotone"
                    dataKey="waterdepth"
                    stroke="#8884d8"
                    activeDot={{ r: 8 }}
                  />
                  :
                  <></>
                }
                {groundwaterelev ? 
                  <Line
                    type="monotone"
                    dataKey="groundwaterelev"
                    hidden
                    stroke="#82ca9d"
                    activeDot={{ r: 8 }}
                  />
                : <></>
                }
                {measurement ? 
                  <Line
                    type="monotone"
                    dataKey="measurement"
                    stroke="#ff00cc"
                    activeDot={{ r: 8 }}
                  />
                  : <></>
                }
            </LineChart>
        );
    }
};

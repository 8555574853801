import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import Grid from '@material-ui/core/Grid';
import Button from "@material-ui/core/Button";
import BackButton from "../common/Button";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import Submit from "../common/Submit";
import TextField from "../common/TextField";
import AppContainer from "../common/AppContainer";

import { connect } from "react-redux";
import * as authActions from "./actions"; 
import * as navActions from "../common/actions"; // Make my own. 

// This is for the login form.
const styles = theme => ({
    loginHeaders: {
        fontSize: 24,
        fontWeight: 100,
        marginBottom: 16,
        color: theme.palette.primary,
    },
    loginError: {
        color: "red",
        "text-align": "center",
        "font-size": 16,
        marginBottom: 24
    },
    marginBottomButton: {
        marginBottom: 24
    },
    greyButton: {
        backgroundColor: "#808080",
        color: "#fff"
    },
    linkColor: {
        color: "#808080",
        float: "right",
        marginTop: 8,
        fontSize: 16,
        fontWeight: 300
    }
});

class LoginPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            basin: 'Kings Wells',
            dmsemail: "kbasin@kbasin.org"
        };
    }
    
    logOut = () => { 
        this.props.authLogout(); 
    }
    
    continueDashboard = () => {
        this.props.navSelectPage("dashboard");
        this.props.history.push("/dashboard");
    };
    errorValidator = values => {
        const validateEmail = username => {
            return !username ? "Username is required." : null;
        };
        const validatePassword = password => {
            return !password ? "Password is required." : null;
        };
        console.log(values.username);
        return {
            username: validateEmail(values.username),
            password: validatePassword(values.password)
        };
    };
    loginSubmit = (values, e, formApi) => {
        console.log('login submitted');
        console.log(values);
        localStorage.setItem('username', values.username);
        localStorage.setItem('password', values.password);
        this.props.authLogin(values).then(() => {
            console.log('something returned from authlogin');
            console.log(values);
            if (this.props.authState.error) {
                console.log('Error with auth state');
                formApi.setValue("password", "");
            } else if (this.props.authState.user) {
                console.log('User selected');
                console.log(this.props.authState.user);
                this.props.history.push("/dashboard"); // Navigate to dashboard when logged in? 
            } else {
                console.log('No real error');
            }
        });
    };
    submitFail = (values, e, formApi) => {
        console.log('error submitting form');
        console.log(values);
    }
    
    render() {
        const { classes, authState } = this.props;
        if (!authState) return "...";
        if (authState !== undefined) {
            if (authState.user) {
                return (<AppContainer>
                    <Typography style={{ "margin-top":"80px" }} variant="title" className={classes.loginHeaders}>
                        Welcome, {authState.user.first_name}
                    </Typography>
                    <Button
                        onClick={this.continueDashboard}
                        variant="raised"
                        color="primary"
                        fullWidth
                        className={classes.marginBottomButton}>
                        Continue to Dashboard
                    </Button>
                    <Button onClick={this.logOut} variant="raised" fullWidth className={classes.greyButton}>
                        Logout
                    </Button>
                </AppContainer>);
            } else {
                return (
                <AppContainer>
                    <div style={{ "margin-top":"80px" }}></div>
                    <Grid container spacing={24}>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={9}>
                       <Typography variant="title" style={{"color":"black"}} className={classes.loginHeaders}>
                            Welcome to the {this.state.basin} DMS
                       </Typography>
                       <div>Intro text place holder</div>
                    </Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={3} style={{"border-right":"1px solid #549ff7"}} >
                        <Typography variant="title" className={classes.loginHeaders}>
                             New Users
                        </Typography>
                        <Typography>
                             Looking to use this Database Management System? Register for an account below.
                             <br />
                        </Typography>
                        <br />
                        <BackButton altLabel="Register" gridSize={24} altAction="/register" />
                        <br />
                        Need Help?
                        <br />
                        Contact us at
                        <br />
                        <a href="mailto:{this.state.dmsemail}" >{this.state.dmsemail}</a>
                    </Grid>
                    <Grid item xs={3}>
                        <Form 
                            dontValidateOnMount="true"
                            validateOnSubmit="true"
                            validateError={this.errorValidator}
                            onSubmitFailure={this.submitFail}
                            onSubmit={this.loginSubmit}>
                            {formApi => (
                            <form onSubmit={formApi.submitForm}>
                                <Typography className={classes.loginHeaders}>
                                    Login
                                </Typography>
                                <TextField field="username" label="Username" fullWidth margin="normal" />
                                <TextField field="password" label="Password" type="password" fullWidth margin="normal" />
                                <div className={classes.loginError}>{authState.error}</div>
                                <Submit
                                    label="Login"
                                    gridSize={24}
                                    loading={authState.pending}
                                    altLabel2="Forget your password?"
                                    altAction2="/password/reset"
                                    />
                                
                            </form>
                        )}
                        </Form>
                    </Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={12}></Grid>
                </Grid>
                </AppContainer>
                );
            }
        } else {
            return (
                <div>Log In Not Logged In Page</div>
            )
        }
        
    }
}
//                            <!--<Button component={Link} className={classes.linkColor} to="/register">Register</Button>-->
/*
<BackButton
                                    component={Link}
                                    style={{"width":"100%", "text-align":"center"}}
                                    className={classes.linkColor}
                                    altAction="/password/reset">
                                    Forget your password?
                                </BackButton>
    */


LoginPage = connect(
    state => ({ authState: state.auth }),
    {
        ...authActions,
        ...navActions
    }
)(LoginPage);

export default withStyles(styles)(withRouter(LoginPage));
import React, { Component } from "react";
import { connect } from "react-redux";
//import PageHeader from '../common/PageHeader';
//import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Submit from "../common/Submit";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import PropTypes from 'prop-types';
//import Select from '@material-ui/core/Select';
//import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';

//import classNames from 'classnames';
//import Radio from '@material-ui/core/Radio';
//import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
//import FormLabel from '@material-ui/core/FormLabel';
//import FormControlLabel from '@material-ui/core/FormControlLabel';
//import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
//import Input from '@material-ui/core/Input';
//import Select from '@material-ui/core/Select';
//import MenuItem from '@material-ui/core/MenuItem';
import AppContainer from "../common/AppContainer";
import * as authActions from "../auth/actions"; 
//import CreateMap from "../common/CreateMap";

const styles = theme => ({
    loginHeaders: {
        fontSize: 24,
        fontWeight: 100,
        marginBottom: 16,
        color: theme.palette.primary
    },
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    marginBottomButton: {
        marginBottom: 24
    },
    greyButton: {
        backgroundColor: "#808080",
        color: "#fff"
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200,
        top: '-14px',
    },
    MuiInputLabel: {
        top: '-45px',
    },
    whiteTextField: {
        backgroundColor: theme.palette.common.white,
        border: '1px solid #ced4da',
        width: '100%',
        borderRadius: '3px',
    },
    margin: {
        /*margin: theme.spacing.unit,*/
        width: '100%',
        padding: '0px',
        margin: '0px',
    },
    MuiFormLabel: {
        top: '-15px',
    },
    passwordCard: {
        borderRadius: '6px',
        borderColor: theme.palette.common.blue,
    },
});

function handleSubmitfunc(event) {
    const { authState } = this.props;
    fetch('/', {
        headers: {
            "Authorization":"Token " + authState.user.auth_token
        },
        method: 'POST',
        body: new FormData(event.target)
    });
}



class PasswordReset extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded:false,
            item: [],
            map: true
        };
        this.handleSubmit = handleSubmitfunc.bind(this);
        if (this.state.item.volumetric === "") {
            this.warningMessage = "You are attemping to create a volume pump record without a Volumetric Pumping Calculation defined for the groundwater well.";
        }
    }
    
    render() {
        //const { isEdit, item } = this.state;
        const { classes, authState } = this.props; // authState seems to be here by magic! :-) //navigation
        if (!authState) return "...";        
        /*if (!isEdit) {
            this.setState({id: "new"});
        } */
        return (
            <AppContainer>
                <div style={{ "margin-top":"80px" }}></div>
                <form onSubmit={this.handleSubmit} style={{"margin-left": "20px", "margin-top":"15px"}} >
                <Grid container gridSize={24} justify="center">
                    <Grid item xs={12} sm={10} md={6}>
                    <Card className={classes.passwordCard} ><CardContent>
                        <Typography variant="title" gutterBottom>
                            Password Reset
                        </Typography>
                    
                        <FormControl className={classes.margin}>
                            <InputLabel class={classes.MuiInputLabel} htmlFor="email">&nbsp;email address</InputLabel>
                            <TextField
                                id="email"
                                type="email"
                                className={classes.whiteTextField}
                            />
                        </FormControl>
                        <br />&nbsp;<br />
                        <Submit
                            label="Reset"
                            loading={authState.pending}
                            greyButton={true}
                            altAction="/"
                            altLabel="Return to Login Page"
                        />
                    </CardContent></Card>
                    </Grid>
                </Grid>
                </form>
            </AppContainer>
        );
    }
}

/*
 *<Submit greyButton={true} altLabel="Return to Login Page" gridSize={12} altAction="/" /></Grid>
 <InputLabel>New Password</InputLabel>
                    <TextField
                        id="password"
                        className={classes.textField}
                    />
                    <InputLabel>Confirm New Password</InputLabel>
                    <TextField
                        id="new_password"
                        className={classes.textField}
                    />
                    
*/
PasswordReset = connect(
    state => ({ authState: state.auth }),
    {
        ...authActions
    }
)(PasswordReset);

PasswordReset.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PasswordReset); 

import React, { Component } from "react";
import { connect } from "react-redux";
import PageHeader from '../common/PageHeader';
import Grid from "@material-ui/core/Grid";
//import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
//import Select from '@material-ui/core/Select';
//import MenuItem from '@material-ui/core/MenuItem';
import Submit from "../common/Submit";

//import classNames from 'classnames';
//import Button from '@material-ui/core/Button';
//import Radio from '@material-ui/core/Radio';
//import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
//import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
//import Input from '@material-ui/core/Input';
//import Select from '@material-ui/core/Select';
//import MenuItem from '@material-ui/core/MenuItem';
import AppContainer from "../common/AppContainer";
import * as authActions from "../auth/actions"; 
//import CreateMap from "../common/CreateMap";

const styles = theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200,
        top: '-14px',
    },
    whiteTextField: {
        backgroundColor: theme.palette.common.white,
        border: '1px solid #ced4da',
        width: '100%',
        borderRadius: '3px',
    },
    MuiFormLabel: {
        top: '-15px',
    },
    MuiInputLabel: {
        top: '-45px',
    },
    MuiFormControl: {
        width: '100%',
    },
    
});

function handleSubmitfunc(event) {
    const { authState } = this.props;
    fetch('/', {
        headers: {
            "Authorization":"Token " + authState.user.auth_token
        },
        method: 'POST',
        body: new FormData(event.target)
    });
}



class newWellForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded:false,
            item: [],
            map: true
        };
        this.handleSubmit = handleSubmitfunc.bind(this);
    }
    
    render() {
        //const { isEdit, item } = this.state;
        const { classes, authState } = this.props; // authState seems to be here by magic! :-) //navigation
        if (!authState) return "...";        
        /*if (!isEdit) {
            this.setState({id: "new"});
        } */
        return (
            <AppContainer>
                <form onSubmit={this.handleSubmit} style={{"margin-left": "20px", "margin-top":"15px"}} >

                <div style={{ "margin-top":"80px" }}></div>
                <Grid container spacing={24}>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={9}>
                        <PageHeader title={ 'User Registration' } />
                        <div>
                            Registration instructions place holder ...........
                        </div>
                    </Grid>
                    
                    <Grid item xs={3}></Grid>
                    <Grid item xs={3}>
                        <FormControl className={classes.formField}>
                            <InputLabel class={classes.MuiInputLabel} htmlFor="first_name">First Name *</InputLabel>
                            <TextField
                                id="first_name"
                                className={classes.whiteTextField}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl className={classes.formField}>
                            <InputLabel class={classes.MuiInputLabel} htmlFor="address">Address</InputLabel>
                            <TextField
                                id="address"
                                className={classes.whiteTextField}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={3}>
                        <FormControl className={classes.formField}>
                            <InputLabel class={classes.MuiInputLabel} htmlFor="last_name">Last Name *</InputLabel>
                            <TextField
                                id="last_name"
                                className={classes.whiteTextField}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl className={classes.formField}>
                            <InputLabel class={classes.MuiInputLabel} htmlFor="address_2">Address 2</InputLabel>
                            <TextField
                                id="address_2"
                                className={classes.whiteTextField}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={3}>
                        <FormControl className={classes.formField}>
                            <InputLabel class={classes.MuiInputLabel} htmlFor="email">Email (this is your user name)*</InputLabel>
                            <TextField
                                id="email"
                                type="email"
                                className={classes.whiteTextField}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl className={classes.formField}>
                            <InputLabel class={classes.MuiInputLabel} htmlFor="city">City</InputLabel>
                            <TextField
                                id="city"
                                className={classes.whiteTextField}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={3}>
                        <FormControl className={classes.formField}>
                            <InputLabel class={classes.MuiInputLabel} htmlFor="phone">Telephone *</InputLabel>
                            <TextField
                                id="phone"
                                type="phone"
                                className={classes.whiteTextField}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl className={classes.formField}>
                            <InputLabel class={classes.MuiInputLabel} htmlFor="state">State</InputLabel>
                            <TextField
                                id="state"
                                className={classes.whiteTextField}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={3}>
                        <FormControl className={classes.formField}>
                            <InputLabel class={classes.MuiInputLabel} htmlFor="password">Password *</InputLabel>
                            <TextField
                                id="password"
                                type="password"
                                className={classes.whiteTextField}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl className={classes.formField}>
                            <InputLabel class={classes.MuiInputLabel} htmlFor="zipcode">Zip Code *</InputLabel>
                            <TextField
                                id="zipcode"
                                type="number"
                                className={classes.whiteTextField}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={3}>
                        <FormControl className={classes.formField}>
                            <InputLabel class={classes.MuiInputLabel} htmlFor="password_2">Retype Password *</InputLabel>
                            <TextField
                                id="password_2"
                                type="password"
                                className={classes.whiteTextField}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={6}>
                        <div>
                            Password requirements: <br />
                            <span style={{"font-size":"smaller", "padding-left":"10px"}}>>Minimum 8 characters</span> <br />
                            <span style={{"font-size":"smaller", "padding-left":"10px"}}>>Cannot be part of your name or email</span> <br />
                            <span style={{"font-size":"smaller", "padding-left":"10px"}}>>Must contain 1 upper case letter, 1 number, and 1 special character</span> <br />
                        </div>
                        <div>
                            <b><span style={{"font-size":"smaller"}}>By continuing with registration, you agree to the terms of use. Please read our data and privacy policy on how data is collected and used</span></b>
                            <br />
                        </div>
                    </Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={3}></Grid>
                    <Grid style={{"text-align":"center"}} item xs={6}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    id="privacy_policy"
                                    color="primary"
                                />
                            }
                            label="I have read the Privacy Policy and Terms of Use"
                        />
                    </Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={6}>
                        <Submit
                            label="Register"
                            gridSize={12}
                            loading={authState.pending}
                            altLabel="Return to Login"
                            altAction="/"
                        />
                    </Grid>
                    <Grid item xs={3}></Grid>
                    
                </Grid>
                </form>
            </AppContainer>
        );
    }
}
newWellForm = connect(
    state => ({ authState: state.auth }),
    {
        ...authActions
    }
)(newWellForm);

newWellForm.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(newWellForm); 

import React from "react";
//import { Scrollbars } from "react-custom-scrollbars";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import classNames from "classnames";
//import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem"; //ListItemIcon, List,
import ListItemText from "@material-ui/core/ListItemText";
//import ListItemIcon from "@material-ui/core/ListItemIcon";
//import { LinearProgress } from "@material-ui/core/LinearProgress";
//import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core";
import { createSelector } from "../common/orm";

//import Collapse from "@material-ui/core/Collapse";
//import ChevronLeftIcon from "material-ui-icons/ChevronLeft";
//import ChevronRightIcon from "material-ui-icons/ChevronRight";
//import ExpandLess from "material-ui-icons/ExpandLess";
//import ExpandMore from "material-ui-icons/ExpandMore";
//import DashboardIcon from "./icons/DashboardIcon";
//import AddNewIcon from "./icons/AddNewIcon";
import Tooltip from "@material-ui/core/Tooltip";
import Collapse from "@material-ui/core/Collapse";
//import Check from "@material-ui/icons/Check"; //water quality
//import Opacity from "@material-ui/icons/Opacity"; // Water droplet
//import ViewList from "@material-ui/icons/ViewList"; // Maintenance Log
//import LocalDrink from "@material-ui/icons/LocalDrink"; // Water Volume Pumping
//import Waves from "@material-ui/icons/Waves"; // Waves
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MaterialIcon from 'material-icons-react';
import * as navActions from "./actions";


const getWell = createSelector(
    (state, ownProps) => state,
    (session, state) => {
        if (state.nav.id === false || state.nav.id === undefined || state.nav.id === "" || state.nav.well_id === "Test Well 1" || state.nav.well_id === "Test Well 2") {
            return session.Well.defaultData[0].ref;
        } else {
            //console.log(session.Well.withId(state.nav.id));
            return session.Well.withId(state.nav.id).ref;
        }
        /*
            .orderBy("well_id", "desc")
            .toModelArray();
        */
    }
    
);

const styles = theme => ({
    drawerPaper: {
        height: "calc(100% - 100px)", // 64 = height of Header + 32 height of Footer
        marginTop: 80, // 64 = height of Header
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        }),
        overflowX: "hidden",
        width: theme.spacing.unit * 7,
        whiteSpace: "nowrap",
        backgroundColor: "#242424"
    },
    drawerOpen: {
        [theme.breakpoints.up("lg")]: {
            width: 325
        },
        [theme.breakpoints.down("md")]: {
            width: 275
        },
        "& $listItemSecond": {
            marginLeft: theme.spacing.unit * 3,
            paddingRight: theme.spacing.unit * 4
        },
        "& $listItemThird": {
            marginLeft: theme.spacing.unit * 6,
            paddingRight: theme.spacing.unit * 7
        },
        "& $overflow": {
            overflowY: "auto"
        }
    },
    selectedPage: {
        backgroundColor: "#4e4e4e"
    },
    pageText: {
        color: "#aaaaaa !important"
    },
    selectedPageText: {
        color: "#aaaaaa !important"
    },
    topPageText: {
        color: "#ffffff"
    },
    listItem: {
        paddingLeft: theme.spacing.unit * 2,
        paddingRight: theme.spacing.unit * 2,
        "&:hover": {
            backgroundColor: "#4e4e4e !important"
        }
    },
    listItemDashboard: {
        borderBottom: "1px solid #00adee"
    },
    listItemField: {
        borderBottom: "1px solid #34a853"
    },
    listItemCrop: {
        borderBottom: "1px solid #7ac143"
    },
    listItemProject: {
        borderBottom: "1px solid #ff7d32"
    },
    listItemSupport: {
        borderBottom: "1px solid #aaaaaa"
    },
    listItemDashboardSelected: {
        borderBottom: "3px solid #00adee"
    },
    listItemCropSelected: {
        borderBottom: "3px solid #7ac143"
    },
    listItemProjectSelected: {
        borderBottom: "3px solid #ff7d32"
    },
    listItemSupportSelected: {
        borderBottom: "3px solid #aaaaaa"
    },
    listItemSecond: {
        transition: theme.transitions.create("margin-left", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: theme.spacing.unit * 3,
        paddingRight: theme.spacing.unit * 4
    },
    listItemThird: {
        transition: theme.transitions.create("margin-left", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: theme.spacing.unit * 6,
        paddingRight: theme.spacing.unit * 7
    },
    overflow: {
        overflowY: "hidden"
    },
    noFlex: {
        padding: 0
    },
    iconSize: {
        flexShrink: 0,
        width: "1em",
        height: "1em",
        fontSize: "24px"
    },
    iconSizeSmaller: {
        flexShrink: 0,
        width: ".8em",
        height: ".8em",
        fontSize: "24px"
    },
    farmHalf: {
        width: "50%",
        display: "inline-block",
        height: 48
    },
    farmHalfText: {
        display: "inline-block",
        verticalAlign: "top",
        maxWidth: "110%"
    },
    farmHalfLarge: {
        [theme.breakpoints.up("lg")]: {
            width: "74%"
        },
        [theme.breakpoints.down("md")]: {
            width: "68%"
        }
    },
    supportHalfLarge: {
        [theme.breakpoints.up("lg")]: {
            width: "82%"
        },
        [theme.breakpoints.down("md")]: {
            width: "76%"
        }
    },
    textOverflow: {
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    textOverflowField: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: "110%"
    },
    svgStyle: {
        width: '24px',
        height: '24px',
    },
    hidden: {
        display: 'none',
    }
});

class WellNavigation extends React.Component {
    // Pulling out the well info to be populated only once a well is loaded.
    /*constructor(props) {
        super(props);
        this.state = {
            wellSelected: false,
        };
    }*/
    render() {
        const {
            classes,
            navState,
            navSelectPage,
            navToggleWellLibrary,
            well
        } = this.props;
        
        /*const item = {
            id: navState.id,
            well_id: navState.well_id
        };*/

        if (navState.wellSelected) {
            return (
                <Collapse in={true} timeout="auto" style={{"overflow":"hidden"}} unmountOnExit className={classNames(classes.overflow)}>
                    <List className={classes.noFlex}>
                        <Tooltip enterDelay={500} title="Selected Well">
                            <ListItem
                                title="Well Detail"
                                button
                                to="/welldetail"
                                onClick={() => navToggleWellLibrary()}
                            >
                                <ListItemText
                                    inset
                                    primary={"Selected Well"}
                                    classes={{
                                        primary: classes.topPageText
                                    }}
                                />
                                <div className={navState.libraryOpen ? '' : classes.hidden }>
                                    <MaterialIcon icon="expand_less" color="blue" />
                                </div>
                                <div className={navState.libraryOpen ? classes.hidden : '' }>
                                    <MaterialIcon icon="expand_more" color="green" />
                                </div>
                            </ListItem>
                        </Tooltip>
                        
                        <Collapse in={navState.libraryOpen} timeout="auto" unmountOnExit style={{"overflow":"hidden"}} >
                            <Tooltip enterDelay={500} title={"Well: " + well.well_id}>
                                <ListItem
                                    title={"Well: " + well.well_id}
                                    button
                                    component={Link}
                                    to={"/welldetail/" + well.id}
                                    onClick={() => navSelectPage("welldetail_" + well.id)}
                                    className={classNames(
                                        classes.listItem
                                    )}
                                >
                                    {/*<FontAwesomeIcon icon="edit" /> */}
                                    <ListItemText
                                        inset
                                        primary={"Well: " + well.well_id}
                                        classes={{
                                            primary: classes.topPageText
                                        }}
                                    />
                                </ListItem>
                            </Tooltip>
                            <List component="div" disablePadding>
                                {/*

                                <ListItem
                                    title="Water Volume Pumping"
                                    button
                                    component={Link}
                                    to="/watervolume"
                                    onClick={() => navSelectPage("watervolume")}
                                    className={classNames(
                                        classes.listItem,
                                        classes.listItemSecond,
                                    )}>
                                    <ListItemIcon className={classes.pageText}>
                                        <LocalDrink className={classes.iconSize} />
                                    </ListItemIcon>
                                    <ListItemText
                                        inset
                                        primary="Water Volume Pumping"
                                        classes={{
                                            primary: classes.topPageText
                                        }}
                                    />
                                </ListItem>
                                <ListItem
                                    title="Create New Well Volume Pump"
                                    button
                                    component={Link}
                                    to="/watervolumepumping"
                                    onClick={() => navSelectPage("watervolumepumping")}
                                    className={classNames(
                                        classes.listItem,
                                        classes.listItemSecond,
                                    )}>
                                    <ListItemIcon className={classes.pageText}>
                                        <svg className={classes.svgStyle} viewBox="0 0 24 24">
                                            <path fill="#ffffff" d="M19,14.5C19,14.5 21,16.67 21,18A2,2 0 0,1 19,20A2,2 0 0,1 17,18C17,16.67 19,14.5 19,14.5M5,18V9A2,2 0 0,1 3,7A2,2 0 0,1 5,5V4A2,2 0 0,1 7,2H9A2,2 0 0,1 11,4V5H19A2,2 0 0,1 21,7V9L21,11A1,1 0 0,1 22,12A1,1 0 0,1 21,13H17A1,1 0 0,1 16,12A1,1 0 0,1 17,11V9H11V18H12A2,2 0 0,1 14,20V22H2V20A2,2 0 0,1 4,18H5Z" />
                                        </svg>
                                    </ListItemIcon>
                                    <ListItemText
                                        inset
                                        primary="Water Volume Pumping"
                                        classes={{
                                            primary: classes.topPageText
                                        }}
                                    />
                                </ListItem>
                                {/*
                                <ListItem
                                    title="Water Levels"
                                    button
                                    component={Link}
                                    to="/waterlevel"
                                    onClick={() => navSelectPage("waterlevel")}
                                    className={classNames(
                                        classes.listItem,
                                        classes.listItemSecond,
                                    )}>
                                    <ListItemIcon className={classes.pageText}>
                                        <MaterialIcon icon="test_tube" color="white" />
                                    </ListItemIcon>
                                    <ListItemText
                                        inset
                                        primary="Water Levels"
                                        classes={{
                                            primary: classes.topPageText
                                        }}
                                    />
                                </ListItem>
                                <ListItem
                                    title="Water Quality"
                                    button
                                    component={Link}
                                    to="/waterquality"
                                    onClick={() => navSelectPage("waterquality")}
                                    className={classNames(
                                        classes.listItem,
                                        classes.listItemSecond,
                                    )}>
                                    <ListItemIcon className={classes.pageText}>
                                        <Check className={classes.iconSize} />
                                    </ListItemIcon>
                                    <ListItemText
                                        inset
                                        primary="Water Quality"
                                        classes={{
                                            primary: classes.topPageText
                                        }}
                                    />
                                </ListItem>
                                
                                <ListItem
                                    title="Pump Tests"
                                    button
                                    component={Link}
                                    to="/pumptests"
                                    onClick={() => navSelectPage("pumptests")}
                                    className={classNames(
                                        classes.listItem,
                                        classes.listItemSecond,
                                    )}>
                                    <ListItemIcon className={classes.pageText}>
                                        <Waves className={classes.iconSize} />
                                    </ListItemIcon>
                                    <ListItemText
                                        inset
                                        primary="Pump Tests"
                                        classes={{
                                            primary: classes.topPageText
                                        }}
                                    />
                                </ListItem>
                                
                                <ListItem
                                    title="Maintenance Log"
                                    button
                                    component={Link}
                                    to="/maintenancelog"
                                    onClick={() => navSelectPage("maintenancelog")}
                                    className={classNames(
                                        classes.listItem,
                                        classes.listItemSecond,
                                    )}>
                                    <ListItemIcon className={classes.pageText}>
                                        <ViewList className={classes.iconSize} />
                                    </ListItemIcon>
                                    <ListItemText
                                        inset
                                        primary="Maintenance Log"
                                        classes={{
                                            primary: classes.topPageText
                                        }}
                                    />
                                </ListItem>
                                */}
                            </List>
                        </Collapse>
                    </List>
                </Collapse>
            )
        } else {
            return (
                <ListItem
                    title="No Well Selected"
                    button
                    component={Link}
                    to="/dashboard"
                    onClick={() => navSelectPage("dashboard")}
                        className={classNames(
                        classes.listItem,
                    )}>
                    <ListItemText
                        inset
                        primary="No Well Selected"
                        classes={{
                            primary:classes.topPageText
                        }}
                    />
                </ListItem>
            )
        }
    }
}
WellNavigation = connect(
    (state, ownProps) => ({
        navState: state.nav,
        authState: state.auth,
        well: getWell(state, ownProps),
    }),
    {
        ...navActions
    }
)(WellNavigation);
export default withStyles(styles)(withRouter(WellNavigation));

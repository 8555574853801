import React from "react";
import { Link } from "react-router-dom";
//import Button from "@material-ui/core/Button";
//import Grid from "@material-ui/core/Grid";
import Paper from '@material-ui/core/Paper';
import Breadcrumbs from '@material-ui/lab/Breadcrumbs';
//import { CircularProgress } from "@material-ui/core/Progress";
import { withStyles } from "@material-ui/core/styles";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

const styles = theme => ({
    submitWrapper: {
        position: "relative"
    },
    progress: {
        top: "50%",
        left: "50%",
        color: "#4CAF50",
        position: "absolute",
        "margin-top": -12,
        "margin-left": -12
    },
    breadCrumb: {
        
    },
    paper: {
        padding: '2px 17px',
        paddingRight: '22px',
        paddingLeft: '20px',
        float: 'left',
        margin: '15px -3px',
    },
    paper2: {
        padding: '2px 17px',
        paddingRight: '22px',
        paddingLeft: '30px',
        float: 'left',
        margin: '15px -3px',
    },
    chevron: {
        padding: '2px 10px',
        float: 'left',
        margin: '17px -10px',
        width: '0px',
        height: '16px',
        borderRight: '3px solid #f0f0f0',
        borderBottom: '3px solid #f0f0f0',
        transform: 'rotate(-45deg)',
        boxShadow: 'none',
    },
    navLinks: {
        color: 'black',
        textDecoration: 'none',
        fontSize: 'smaller',
        fontWeight: '400',
    }
});

const handleClick = function() {
    //
}

const BreadcrumbNav = ({ level1, level1link, level2=null, level2link, level3=null, level3link, classes=this.props}) => (
    <div>
        <Paper className={classes.paper}>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} arial-label="Breadcrumb">
                <Link color="inherit" href={level1link} to={level1link} className={classes.navLinks} onClick={handleClick}>
                    {level1}
                </Link>
            </Breadcrumbs>
        </Paper>
        <Paper className={classes.chevron}></Paper>
    {level2 ? (
        <>
        <Paper className={classes.paper2}>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} arial-label="Breadcrumb">
                <Link color="inherit" href={level2link} to={level2link} className={classes.navLinks} onClick={handleClick}>
                    {level2}
                </Link>
            </Breadcrumbs>
        </Paper>
        <Paper className={classes.chevron}></Paper>
        </>
    ):(<div></div>)}
    
    {level3 ? (
        <>
        <Paper className={classes.paper2}>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} arial-label="Breadcrumb">
                <Link color="inherit" href={level3link} to={level3link} className={classes.navLinks} onClick={handleClick}>
                    {level3}
                </Link>
            </Breadcrumbs>
        </Paper>
        <Paper className={classes.chevron}></Paper>
        </>
    ):(<div></div>)}
    </div>
);

export default withStyles(styles)(BreadcrumbNav);


/*
 *
4. Breadcrumbs Links:
background-color: inherit
color: black
border-color:blue
double-right-arrow

:first-child
right_arrow
*/
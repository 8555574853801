import React from "react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
//import { CircularProgress } from "material-ui/Progress";
import { withStyles } from "@material-ui/core/styles";

const styles = {
    submitWrapper: {
        position: "relative"
    },
    progress: {
        top: "50%",
        left: "50%",
        color: "#4CAF50",
        position: "absolute",
        "margin-top": -12,
        "margin-left": -12
    }
};

const BackButton = ({ label, subLabel, altAction, altLabel, classes, loading, gridSize = 12, justify = "center", greyButton = false}) => (
    <Grid container justify={justify}>
        {greyButton
            ? 
            <Grid item xs={gridSize / 2}>
                <Button fullWidth component={Link} to={altAction} variant="raised" color="grey">
                    {altLabel}
                </Button>
            </Grid>
         :
            <Grid item xs={gridSize / 2}>
                <Button fullWidth component={Link} to={altAction} variant="raised" color="primary">
                    {altLabel}
                </Button>
            </Grid>
        }  
    </Grid>
);
//{loading && <CircularProgress size={24} className={classes.progress} />}
export default withStyles(styles)(BackButton);

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";

//import Card from "@material-ui/core/Card";
//import CardContent from "@material-ui/core/CardContent";
//import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import AppContainer from "../common/AppContainer";
import Submit from "../common/Submit";
import TextField from "../common/TextField";

import { connect } from "react-redux";
import * as authActions from "./actions";

const styles = {
    passwordError: {
        color: "red",
        "text-align": "center",
        "font-size": 16
    }
};

class PasswordPage extends Component {
    errorValidator = values => {
        const validateUsername = username => {
            var re = /a-Z1-9/;
            return !re.test(username) ? "Invalid Username" : null;
        };
        return {
            username: validateUsername(values.username)
        };
    };

    passwordSubmit = (values, e, formApi) => {
        this.props.authPasswordReset(values).then(() => formApi.clearAll());
    };

    render() {
        const { classes, passwordReset } = this.props;
        return (
            <AppContainer>

                <Typography variant="title" gutterBottom>
                    Password Reset
                </Typography>
                <Form
                    dontValidateOnMount="true"
                    validateOnSubmit="true"
                    validateError={this.errorValidator}
                    onSubmit={this.passwordSubmit}>
                    {formApi => (
                        <form onSubmit={formApi.submitForm}>
                            <div className={classes.passwordError}>{passwordReset.error}</div>
                            {passwordReset.success && (
                                <p>
                                    A password reset email has been sent to the specified email address:{" "}
                                    {passwordReset.success.username}. If a user with this address exists
                                    then you will receive instructions on how to reset. If you do not
                                    receive an email within five minutes check your spam folder.
                                </p>
                            )}
                            <TextField field="username" label="Username" fullWidth margin="normal" />
                            <Submit
                                label="Reset"
                                loading={passwordReset.pending}
                                altAction="/"
                                altLabel="Return to Home Page"
                            />
                        </form>
                    )}
                </Form>
                            
            </AppContainer>
        );
    }
}

PasswordPage = connect(
    state => ({ passwordReset: (state.auth && state.auth.passwordReset) || {} }),
    authActions
)(PasswordPage);

export default withStyles(styles)(withRouter(PasswordPage));

import React, { Component } from "react";
import { connect } from "react-redux";
import PageHeader from '../common/PageHeader';
//import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
//import Select from '@material-ui/core/Select';
//import MenuItem from '@material-ui/core/MenuItem';
//import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import AppContainer from "../common/AppContainer";
import * as authActions from "../auth/actions"; 

const styles = theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200,
        top: '-14px',
    },
    MuiFormLabel: {
        top: '-15px',
    }
});

function handleSubmitfunc(event) {
    const { authState } = this.props;
    fetch('/', {
        headers: {
            "Authorization":"Token " + authState.user.auth_token
        },
        method: 'POST',
        body: new FormData(event.target)
    });
}

class WellVolumePumpForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded:false,
            item: []
        };
        this.handleSubmit = handleSubmitfunc.bind(this);
        if (this.state.item.volumetric === "") {
            this.warningMessage = "You are attemping to create a volume pump record without a Volumetric Pumping Calculation defined for the groundwater well.";
        }
    }
    
    render() {
        const { item } = this.state; //isEdit, 
        const { classes, authState } = this.props; // authState seems to be here by magic! :-) //navigation
        if (!authState) return "...";        
        /*if (!isEdit) {
            this.setState({id: "new"});
        } */
        return (
            <AppContainer authenticated>
                <PageHeader title={item.well_id + ' Volume Pump Entry '} />
                <form onSubmit={this.handleSubmit} style={{"margin-left": "20px", "margin-top":"15px"}} >
                    
                    <InputLabel>Start Date</InputLabel>
                    <TextField
                        id="startdate"
                        type="date"
                        className={classes.textField}
                    />
                    <InputLabel>End Date</InputLabel>
                    <TextField
                        id="enddate"
                        type="date"
                        className={classes.textField}
                    />
                    <br />
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Calculation Type</FormLabel>
                        <RadioGroup
                            aria-label="Calculation Type"
                            name="calculationtype"
                            style={{ "display":"inline-block" }}
                        >
                            <FormControlLabel value="daily" control={<Radio />} label="Daily" />
                            <FormControlLabel value="weekly" control={<Radio />} label="Weekly" />
                            <FormControlLabel value="monthly" control={<Radio />} label="Monthly" />
                            <FormControlLabel value="semiannually" control={<Radio />} label="Semi-Annually" />
                            <FormControlLabel value="annually" control={<Radio />} label="Annually" />
                        </RadioGroup>
                    </FormControl>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.state.pgmeter}
                                value="pgmeter" />
                        }
                        label="New PG&E Meter"
                    />
                    <br />
                    <TextField
                        id="volumetric"
                        label="Volumetric Pumping Calculation"
                        disabled
                        className={classes.textField}
                    />
                    <TextField
                        id="collectedby"
                        label="Collected By"
                        className={classes.textField}
                    />
                    <br />
                    <TextField
                        id="volumepumped"
                        label="Volume Pumped (AF)"
                        type="number"
                        className={classes.textField}
                    />
                    <TextField
                        id="additionalparameters"
                        label="Additional Calculation Parameters"
                        type="number"
                        className={classes.textField}
                    />
                    <br />
                    <TextField
                        id="wellvolumepumpnotes"
                        label="Notes"
                        type="number"
                        fullWidth
                        className={classes.textField}
                    />
                    <br />
                    <Input type="hidden" value={this.state.id} id="id" />
                    <Button type="submit">Submit</Button>
                </form>
            </AppContainer>
        );
    }
}
WellVolumePumpForm = connect(
    state => ({ authState: state.auth }),
    {
        ...authActions
    }
)(WellVolumePumpForm);

WellVolumePumpForm.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(WellVolumePumpForm); 

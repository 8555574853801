import React from 'react';
import { Map, TileLayer, WMSTileLayer, Marker, Popup  } from 'react-leaflet'; //type MapControlProps, , MapControl, withLeaflet
//import { Control, DomUtil, DomEvent } from "leaflet";

//import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
//import Button from "@material-ui/core/Button";
import CollapsibleLayersControl from '../common/CollapsibleLayersControl';

import MapLayers from '../common/MapLayers';
import ZoomExtent from "../common/ZoomExtent";
//const { Overlay } = CollapsibleLayersControl;

class DashboardMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lat: 36.642,
      lng: -119.817,
      zoom: 13,
      collapsed: false,
      bounds: [[36.33, -120.44], [37.02, -118.91]],
      //markerpos: this.props.markerpos,
    };
  }
  handleZoomTo(lat, lng) {
    this.setState({lat:lat});
    this.setState({lng:lng});
    //render(); // Probably the wrong way to handle it.
  }
  handleZoomLevel(zoom) {
    //console.log('Zooming');
    //console.log(zoom);
    this.setState({zoom:zoom});
  };
  zoomIn() {
    this.context.map.zoomIn();
  }
  zoomOut() {
    this.context.map.zoomOut();
  }
  zoomExtent() {
    this.context.map.fitBounds(this.state.bounds)
  }
  componentDidUpdate(prevprops) {
    const { formApi, markerpos } = this.props;
    if (markerpos !== undefined) {
      if (markerpos[0] !== prevprops.markerpos[0]) {
        formApi.setValue("latitude", parseFloat(markerpos[0]));
        formApi.setValue("longitude", parseFloat(markerpos[1]));
        // Flipping values here for GeoJson.
        formApi.setValue("location", {type:"Point", coordinates:[parseFloat(markerpos[1]), parseFloat(markerpos[0])]});
        formApi.setValue("location_coordinates", '{type:"Point", coordinates:['+parseFloat(markerpos[1])+', '+parseFloat(markerpos[0])+']}');
      }
    }
  }
  addMarker = (e) => {
    if (this.props.editable) {
      this.props.updatePosition(e);
    }
  }
  
  markerLayers() {
    // Get all markers from /scripts/autocomplete.php
  }
  ExpandFunc(temp) {
    //console.log('Expanding');
    temp.preventDefault();
  }
  CollapseFunc(temp) {
    this.setState({ collapsed:!this.state.collapsed });
  }
  // style={{ position: "absolute", top:"40px", left:"10px" }}
  render() {
    const { zoom, lat, lng, collapsed, bounds } = this.state;
    const { containerheight="100%", markerpos,  } = this.props; //formApi, editable
    var position = [lat, lng];
    if (markerpos !== undefined && markerpos !== false) {
      //console.log('Marker First');
      //console.log(markerpos);
      position = markerpos;
      //console.log('marker position: ');
      //console.log(markerpos);
    }
    // Account for value of marker coming from geojson:
    if (markerpos !== undefined) {
      if ((markerpos[0] > 55 || markerpos[0] < 26) && (markerpos[1] < -124 || markerpos[1] > -65)) {
        // Flip
        position = [markerpos[1], markerpos[0]];
      }
    }
    return (
      <Map
        style={{"width":"100%", height:containerheight}}
        center={position}
        zoom={zoom}
        maxZoom="20"
        onClick={this.addMarker}
        updatePosition={this.props.updatePosition}
        bounds={bounds}>
        <ZoomExtent position="topleft" />        
        <CollapsibleLayersControl
          position="topright"
          collapsed={collapsed}>
            <CollapsibleLayersControl.BaseLayer checked name="Esri Topographic">
              <TileLayer
                attribution='ESRI'
                url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}"
              />
            </CollapsibleLayersControl.BaseLayer>
            <CollapsibleLayersControl.BaseLayer name="Esri Streets">
              <TileLayer
                attribution='ESRI'
                url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
              />
            </CollapsibleLayersControl.BaseLayer>
            <CollapsibleLayersControl.BaseLayer name="Esri Imagery">
              <TileLayer
                attribution='ESRI'
                url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
              />
            </CollapsibleLayersControl.BaseLayer>
            <CollapsibleLayersControl.Overlay checked name="Groundwater Wells">
              <MapLayers />
            </CollapsibleLayersControl.Overlay>
            <CollapsibleLayersControl.Overlay checked name="Kings Subbasin Boundary">
          <WMSTileLayer
            layers="kings_subbasin"
            transparent="true"
            format="image/png"
            map="/var/www/kings/maps/map.map"
            url="https://kings.houstoneng.net/cgi-bin/mapserv"
          />
        </CollapsibleLayersControl.Overlay>
        { !position ? <></> :
          <CollapsibleLayersControl.Overlay checked name="Current Position">
            <Marker position={position}>
              <Popup>Current Location</Popup>
            </Marker>
          </CollapsibleLayersControl.Overlay>
        }
        <CollapsibleLayersControl.Overlay checked name="Groundwater Sustainability Agencies">
          <WMSTileLayer
            layers="gsa"
            transparent="true"
            format="image/png"
            map="/var/www/kings/maps/map.map"
            url="https://kings.houstoneng.net/cgi-bin/mapserv"
          />
        </CollapsibleLayersControl.Overlay>
        <CollapsibleLayersControl.Overlay name="E Clay Eastern Extent">
          <WMSTileLayer
            layers="clay"
            transparent="true"
            format="image/png"
            map="/var/www/kings/maps/map.map"
            url="https://kings.houstoneng.net/cgi-bin/mapserv"
          />
        </CollapsibleLayersControl.Overlay>
        <CollapsibleLayersControl.Overlay name="Depth to Base of Unconfined Groundwater">
          <WMSTileLayer
            layers="depth_base"
            transparent="true"
            format="image/png"
            map="/var/www/kings/maps/map.map"
            url="https://kings.houstoneng.net/cgi-bin/mapserv"
          />
        </CollapsibleLayersControl.Overlay>
        <CollapsibleLayersControl.Overlay name="Specific Yield Units">
          <WMSTileLayer
            layers="yield_units"
            transparent="true"
            format="image/png"
            map="/var/www/kings/maps/map.map"
            url="https://kings.houstoneng.net/cgi-bin/mapserv"
          />
        </CollapsibleLayersControl.Overlay>
        <CollapsibleLayersControl.Overlay name="Land Use DWR">
          <WMSTileLayer
            layers="landuse"
            transparent="true"
            format="image/png"
            map="/var/www/kings/maps/map.map"
            url="https://kings.houstoneng.net/cgi-bin/mapserv"
          />
        </CollapsibleLayersControl.Overlay>
        
        <CollapsibleLayersControl.Overlay name="Water Level Contours - Spring 1963">
          <WMSTileLayer
            layers="spring_1963"
            transparent="true"
            format="image/png"
            map="/var/www/kings/maps/map.map"
            url="https://kings.houstoneng.net/cgi-bin/mapserv"
          />
        </CollapsibleLayersControl.Overlay>
        <CollapsibleLayersControl.Overlay name="Water Level Contours - Spring 1964">
          <WMSTileLayer
            layers="spring_1964"
            transparent="true"
            format="image/png"
            map="/var/www/kings/maps/map.map"
            url="https://kings.houstoneng.net/cgi-bin/mapserv"
          />
        </CollapsibleLayersControl.Overlay>
        <CollapsibleLayersControl.Overlay name="Water Level Contours - Spring 1965">
          <WMSTileLayer
            layers="spring_1965"
            transparent="true"
            format="image/png"
            map="/var/www/kings/maps/map.map"
            url="https://kings.houstoneng.net/cgi-bin/mapserv"
          />
        </CollapsibleLayersControl.Overlay>
        <CollapsibleLayersControl.Overlay name="Water Level Contours - Spring 1996">
          <WMSTileLayer
            layers="spring_1996"
            transparent="true"
            format="image/png"
            map="/var/www/kings/maps/map.map"
            url="https://kings.houstoneng.net/cgi-bin/mapserv"
          />
        </CollapsibleLayersControl.Overlay>
        <CollapsibleLayersControl.Overlay name="Water Level Contours - Spring 1997">
          <WMSTileLayer
            layers="spring_1997"
            transparent="true"
            format="image/png"
            map="/var/www/kings/maps/map.map"
            url="https://kings.houstoneng.net/cgi-bin/mapserv"
          />
        </CollapsibleLayersControl.Overlay>
        <CollapsibleLayersControl.Overlay name="Water Level Contours - Spring 1998">
          <WMSTileLayer
            layers="spring_1998"
            transparent="true"
            format="image/png"
            map="/var/www/kings/maps/map.map"
            url="https://kings.houstoneng.net/cgi-bin/mapserv"
          />
        </CollapsibleLayersControl.Overlay>
        <CollapsibleLayersControl.Overlay name="Water Level Contours - Spring 1999">
          <WMSTileLayer
            layers="spring_1999"
            transparent="true"
            format="image/png"
            map="/var/www/kings/maps/map.map"
            url="https://kings.houstoneng.net/cgi-bin/mapserv"
          />
        </CollapsibleLayersControl.Overlay>
        <CollapsibleLayersControl.Overlay name="Water Level Contours - Spring 2000">
          <WMSTileLayer
            layers="spring_2000"
            transparent="true"
            format="image/png"
            map="/var/www/kings/maps/map.map"
            url="https://kings.houstoneng.net/cgi-bin/mapserv"
          />
        </CollapsibleLayersControl.Overlay>
        <CollapsibleLayersControl.Overlay name="Water Level Contours - Spring 2001">
          <WMSTileLayer
            layers="spring_2001"
            transparent="true"
            format="image/png"
            map="/var/www/kings/maps/map.map"
            url="https://kings.houstoneng.net/cgi-bin/mapserv"
          />
        </CollapsibleLayersControl.Overlay>
        <CollapsibleLayersControl.Overlay name="Water Level Contours - Spring 2002">
          <WMSTileLayer
            layers="spring_2002"
            transparent="true"
            format="image/png"
            map="/var/www/kings/maps/map.map"
            url="https://kings.houstoneng.net/cgi-bin/mapserv"
          />
        </CollapsibleLayersControl.Overlay>
        <CollapsibleLayersControl.Overlay name="Water Level Contours - Spring 2003">
          <WMSTileLayer
            layers="spring_2003"
            transparent="true"
            format="image/png"
            map="/var/www/kings/maps/map.map"
            url="https://kings.houstoneng.net/cgi-bin/mapserv"
          />
        </CollapsibleLayersControl.Overlay>
        <CollapsibleLayersControl.Overlay name="Water Level Contours - Spring 2004">
          <WMSTileLayer
            layers="spring_2004"
            transparent="true"
            format="image/png"
            map="/var/www/kings/maps/map.map"
            url="https://kings.houstoneng.net/cgi-bin/mapserv"
          />
        </CollapsibleLayersControl.Overlay>
        <CollapsibleLayersControl.Overlay name="Water Level Contours - Spring 2005">
          <WMSTileLayer
            layers="spring_2005"
            transparent="true"
            format="image/png"
            map="/var/www/kings/maps/map.map"
            url="https://kings.houstoneng.net/cgi-bin/mapserv"
          />
        </CollapsibleLayersControl.Overlay>
        <CollapsibleLayersControl.Overlay name="Water Level Contours - Spring 2006">
          <WMSTileLayer
            layers="spring_2006"
            transparent="true"
            format="image/png"
            map="/var/www/kings/maps/map.map"
            url="https://kings.houstoneng.net/cgi-bin/mapserv"
          />
        </CollapsibleLayersControl.Overlay>
        <CollapsibleLayersControl.Overlay name="Water Level Contours - Spring 2007">
          <WMSTileLayer
            layers="spring_2007"
            transparent="true"
            format="image/png"
            map="/var/www/kings/maps/map.map"
            url="https://kings.houstoneng.net/cgi-bin/mapserv"
          />
        </CollapsibleLayersControl.Overlay>
        <CollapsibleLayersControl.Overlay name="Water Level Contours - Spring 2008">
          <WMSTileLayer
            layers="spring_2008"
            transparent="true"
            format="image/png"
            map="/var/www/kings/maps/map.map"
            url="https://kings.houstoneng.net/cgi-bin/mapserv"
          />
        </CollapsibleLayersControl.Overlay>
        <CollapsibleLayersControl.Overlay name="Water Level Contours - Spring 2009">
          <WMSTileLayer
            layers="spring_2009"
            transparent="true"
            format="image/png"
            map="/var/www/kings/maps/map.map"
            url="https://kings.houstoneng.net/cgi-bin/mapserv"
          />
        </CollapsibleLayersControl.Overlay>
        <CollapsibleLayersControl.Overlay name="Water Level Contours - Spring 2010">
          <WMSTileLayer
            layers="spring_2010"
            transparent="true"
            format="image/png"
            map="/var/www/kings/maps/map.map"
            url="https://kings.houstoneng.net/cgi-bin/mapserv"
          />
        </CollapsibleLayersControl.Overlay>
        <CollapsibleLayersControl.Overlay name="Water Level Contours - Spring 2011">
          <WMSTileLayer
            layers="spring_2011"
            transparent="true"
            format="image/png"
            map="/var/www/kings/maps/map.map"
            url="https://kings.houstoneng.net/cgi-bin/mapserv"
          />
        </CollapsibleLayersControl.Overlay>
        <CollapsibleLayersControl.Overlay name="Water Level Contours - Spring 2012">
          <WMSTileLayer
            layers="spring_2012"
            transparent="true"
            format="image/png"
            map="/var/www/kings/maps/map.map"
            url="https://kings.houstoneng.net/cgi-bin/mapserv"
          />
        </CollapsibleLayersControl.Overlay>
        <CollapsibleLayersControl.Overlay name="Water Level Contours - Spring 2013">
          <WMSTileLayer
            layers="spring_2013"
            transparent="true"
            format="image/png"
            map="/var/www/kings/maps/map.map"
            url="https://kings.houstoneng.net/cgi-bin/mapserv"
          />
        </CollapsibleLayersControl.Overlay>
        <CollapsibleLayersControl.Overlay name="Water Level Contours - Spring 2014">
          <WMSTileLayer
            layers="spring_2014"
            transparent="true"
            format="image/png"
            map="/var/www/kings/maps/map.map"
            url="https://kings.houstoneng.net/cgi-bin/mapserv"
          />
        </CollapsibleLayersControl.Overlay>
        <CollapsibleLayersControl.Overlay name="Water Level Contours - Spring 2015">
          <WMSTileLayer
            layers="spring_2015"
            transparent="true"
            format="image/png"
            map="/var/www/kings/maps/map.map"
            url="https://kings.houstoneng.net/cgi-bin/mapserv"
          />
        </CollapsibleLayersControl.Overlay>
        <CollapsibleLayersControl.Overlay name="Water Level Contours - Spring 2016">
          <WMSTileLayer
            layers="spring_2016"
            transparent="true"
            format="image/png"
            map="/var/www/kings/maps/map.map"
            url="https://kings.houstoneng.net/cgi-bin/mapserv"
          />
        </CollapsibleLayersControl.Overlay>
        </CollapsibleLayersControl>
      </Map>
    );
  }
}

export default DashboardMap;
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core";

import AppContainer from "../common/AppContainer";
import Submit from "../common/Submit";
import TextField from '../common/TextField';
import * as authActions from "../auth/actions";

//import PageHeader from '../common/PageHeader';
//import Button from '@material-ui/core/Button';
//import FormControl from '@material-ui/core/FormControl';
//import Checkbox from '@material-ui/core/Checkbox';
//import InputLabel from '@material-ui/core/InputLabel';
//import BreadcrumbNav from "../common/BreadCrumb";

const styles =  {
    registerError: {
        color: "red",
        textAlign: "center"
    }
};

class UserProfile extends Component {
    errorValidator = values => {
        const validateEmail = email => {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return !re.test(email) ? "Invalid Email Address" : null;
        };
        return {
            email: validateEmail(values.email)
        };
    };
    errorValidatorPassword = values => {
        const validatePasswordSingle = password => {
            if (!password || password.length < 8) return "Invalid password";
            else return null;
        };
        const validatePassword = (password, password2) => {
            if (password !== password2) return "Passwords do not match";
            else if (!password || password.length < 8) return "Invalid password";
            else return null;
        };
        return {
            current_password: validatePasswordSingle(values.current_password),
            new_password: validatePassword(values.new_password, values.re_new_password),
            re_new_password: validatePassword(values.new_password, values.re_new_password)
        };
    };
    submitForm = values => {
        this.props.authUpdate(values).then(() => {
            if (this.props.update.success) {
                this.props.history.push("/dashboard");
            }
        });
    };
    submitFormPassword = values => {
        this.props.authPasswordChange(values).then(() => {
            if (this.props.change.success) {
                this.props.history.push("/dashboard");
            }
        });
    };
        
    render() {
        const { classes, update, change, authState } = this.props;
        console.log(authState.user);
        return (
            <AppContainer authenticated>
                <Grid container spacing={24}>
                    <Form
                        dontValidateOnMount="true"
                        validateOnSubmit="true"
                        defaultValues={authState.user}
                        validateError={this.errorValidator}
                        onSubmit={this.submitForm}>
                        {formApi => (
                            <Grid item xs={12}>
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={40}>
                                        <Grid item xs={12} md={8} lg={6}>
                                            <Typography variant="title" gutterBottom>
                                                User Profile
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={40} gutterBottom>
                                        <Grid item xs={10} sm={6} md={4} lg={3}>
                                            <TextField
                                                field="username"
                                                label="Username"
                                                fullWidth
                                                disabled
                                                margin="normal"
                                            />
                                            <TextField field="email" label="Email" fullWidth margin="normal" />
                                            &nbsp;
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} lg={3}>
                                            <TextField
                                                field="name"
                                                label="Name"
                                                fullWidth
                                                margin="normal" />
                                            <TextField
                                                field="first_name"
                                                label="First Name"
                                                fullWidth
                                                margin="normal"
                                            />
                                            <TextField
                                                field="last_name"
                                                label="Last Name"
                                                fullWidth
                                                margin="normal"
                                            />
                                            
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={40}>
                                        <Grid item xs={12} md={8} lg={6}>
                                            <Submit
                                                label="Update User Information"
                                                altAction="/dashboard"
                                                altLabel="Return to Dashboard"
                                                loading={update.pending}
                                            />
                                            <Typography className={classes.registerError}>{update.error}</Typography>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Grid>
                        )}
                    </Form>
                <Grid item xs={12} md={8} lg={6}>
                    <Divider />
                </Grid>
                <Form
                    dontValidateOnMount="true"
                    validateOnSubmit="true"
                    validateError={this.errorValidatorPassword}
                    onSubmit={this.submitFormPassword}>
                    {formApi => (
                        <Grid item xs={12}>
                            <form onSubmit={formApi.submitForm}>
                                <Grid container spacing={40}>
                                    <Grid item xs={12} md={8} lg={6}>
                                        <Typography variant="title" gutterBottom>
                                            Update Password
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={40} gutterBottom>
                                    <Grid item xs={10} sm={6} md={4} lg={3}>
                                        <TextField
                                            field="current_password"
                                            label="Current Password"
                                            type="password"
                                            fullWidth
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={10} sm={6} md={4} lg={3}>
                                        <TextField
                                            field="new_password"
                                            label="New Password"
                                            type="password"
                                            fullWidth
                                            margin="normal"
                                        />
                                        <TextField
                                            field="re_new_password"
                                            label="Retype New Password"
                                            type="password"
                                            fullWidth
                                            margin="normal"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={40}>
                                    <Grid item xs={12} md={8} lg={6}>
                                        <Submit
                                            label="Update Password"
                                            altAction="/dashboard"
                                            altLabel="Return to Dashboard"
                                            loading={change.pending}
                                        />
                                        <Typography className={classes.registerError}>{change.error}</Typography>
                                    </Grid>
                                </Grid>
                            </form>
                        </Grid>
                    )}
                </Form>
            </Grid>
                
            </AppContainer>
        );
    }
}
UserProfile = connect(
    state => ({
        authState: state.auth,
        update: (state.auth && state.auth.update) || {},
        change: (state.auth && state.auth.change) || {}
    }),
    authActions
)(UserProfile);

export default withStyles(styles)(withRouter(UserProfile)); 

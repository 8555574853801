import { fk, many } from 'redux-orm'; // attr, Schema
import orm, { Model } from "../common/orm";

export class Well extends Model {
    static get apiUrl() {
        return "/wells/";
    }
    static get source() {
        return "/wells/";
    }
    static get fields() {
        return {};
    }
    static get modelName() {
        return "Well";
    }
    static get defaultData() {
        return [
            {
                id: 1,
                well_id: "Test Well 1",
                state_well: true,
                agency_well_id: "Madera Irrigation District"
            },
            {
                id: 2,
                well_id: 'Test Well 2',
                state_well: false,
                agency_well_id: "Madera Irrigation District"
            }
        ];
    }
}
orm.register(Well);

export class WellReference extends Model {
    static get apiUrl() {
        return "/wellreferences/";
    }
    static get source() {
        return "/wellreferences/";
    }
    static get fields() {
        return {
            well_id: fk('Well', 'wellrefs')
            };
    }
    static get modelName() {
        return "WellReference";
    }
    static get defaultData() {
        return [
            {
                id: 1,
                well: "1",
                date: '2015-01-01',
                ground_surface: "4.28",
                reference_point: '3.8',
                total_depth: '2.8',
                datum: '18',
                method: 'Volume'
            },
            {
                id: 1,
                well: "2",
                date: '2016-01-01',
                ground_surface: "18.28",
                reference_point: '4.8',
                total_depth: '2.1',
                datum: '57',
                method: 'Volume'
            }
        ];
    }
}
orm.register(WellReference);

export class WellTime extends Model {
    static get apiUrl() {
        return "/welltimes/";
    }
    static get source() {
        return "/welltimes/";
    }
    static get fields() {
        return {
            well_id: many('Well', 'welltimes')
        };
    }
    static get modelName() {
        return "WellTime";
    }
    static get defaultData() {
        return [
            {
                id: 1,
                date: '2018-01-01',
                measurement: 'none',
                staticpumping: 'none',
                comments: 'This is a test comment.',
                waterdepth: 32.14,
                groundwaterelev: 148.38,
                sampledby: 'none',
                updateby: 1,
                well_id: [325]
            }
        ];
    }
}
orm.register(WellTime);


export class WellParameter extends Model {
    static get apiUrl() {
        return "/wellparameters/";
    }
    static get source() {
        return "/wellparameters/";
    }
    static get fields() {
        return {
            well_id: many('Well', 'wellparameters'),
        };
    }
    static get modelName() {
        return "WellParameter";
    }
    static get defaultData() {
        return [
            {
                id: 1,
                name: "Field",
                type: "text", // numeric, checkbox, drop down
                values: [],
                order: 1,
                
            }
        ];
    }
}
orm.register(WellParameter);

export class WellCustom extends Model {
    static get apiUrl() {
        return "/results/";
    }
    static get source() {
        return "/results/";
    }
    static get fields() {
        return {
            well_id: many('Well', 'wellvalues'),
            //type_id: many('WellParameter', 'wellparams'),
        };
    }
    static get modelName() {
        return "WellCustom";
    }
    static get defaultData() {
        return [
            {
                id: 1,
                value: "Test",
                type_id: 5, // text, numeric, checkbox, drop down
                well_id: 203,
                
            }
        ];
    }
}
orm.register(WellCustom);

/*
auth_user {
    id
    password
    last_login
    is_superuser
    username
    first_name,
    last_name
email
is_staff
is_active
date_joined
}
export class WellVolumePump extends Model {
    // No Data
}
export class WellParameter extends Model {
    //* No Data present
}
export class WellQuality extends Model {
    //* No Data present 
}
export class WellTestPump extends Model {
    //* No data present 
}
*/
/*
export const schema = new Schema();
schema.register(Well, WellReference);

export default schema;
*/
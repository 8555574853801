import React, { Component } from "react";
import Table from "@material-ui/core/Table";
//import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import MaterialIcon from 'material-icons-react';
import { withStyles } from "@material-ui/core/styles";
import EnhancedTableHead from "../common/EnhancedTableHead";
import TimeSeriesEdit from "../charts/TimeSeriesEdit";

import { WellTime } from "../wells/models";
//import { createSelector } from "../common/orm";
import { connect } from "react-redux";
import * as navActions from "../common/actions";

//import { welltimesconfig } from '../../api/formConfigs';

const styles = ({
    ts_hidden: {
        display: 'none'
    },
    ts_shown: {
        display: 'table-cell'
    },
    tableCell: {
        padding: "4px 24px 4px 24px",
    }
});

class EnhancedTableRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hidden: null
        };
    }
    
    render() {
        const {classes, row, ts_data, editRow, fieldconf } = this.props;
        var is_actionrow;
        var filterarray = Object.values(fieldconf)
                            .filter(rowdata => rowdata.id === ts_data);
        if (filterarray.length === 0) {
            filterarray = true;
            is_actionrow = false;
        } else {
            is_actionrow = filterarray[0].label === 'Actions';
            filterarray = filterarray[0].hidden;   
        }
        return (
                <TableCell
                    className={
                        filterarray
                    ? classes.ts_hidden : classes.ts_shown }>
                    { is_actionrow ?
                        <Button
                            onClick={() => editRow(row)}
                        >Edit</Button>
                    :
                        (typeof row[ts_data] !== 'object' && row[ts_data] !== null ? row[ts_data].toString() : 'None')
                    }
                </TableCell>
        )
    }
}

class TimeSeriesTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            order: "desc",
            orderBy: "date",
        };
    };
    editRow = rowdata => {
        console.log('Editing Row: ');
        const { ormWellTimeLoadDetail } = this.props;
        console.log(rowdata);
        this.props.welltime_selected(rowdata.id);
        ormWellTimeLoadDetail('?id='+rowdata.id+'&', data => {
            // We need to load a WellTimes instance, not just the data. 
            this.setState({wellTimes: data})
        });
        this.setState({
            open: true,
            selectedValues: rowdata
        });
    };
    toggleColumns(columnName) {
        this.setState({[columnName]: !this.state[columnName]});
        // This probably needs to be put in the global state as it will be tedious to toggle them each time you view data. 
    };
    handleClose = value => {
        this.setState({ selectedValue: value, open: false });
    }
    
    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = "desc";
        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }
        order === "desc"
            ? this.props.data.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
            : this.props.data.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));
        this.setState({ order, orderBy });
    };
    onSubmit = values => {
        const { ormWellTimeUpdate } = this.props;
        ormWellTimeUpdate(values);
        /* Now we need to find a way to trigger an updated of the related model so the screen refreshes. */
        this.setState({ open: false });
    };
    render() {
        const { order, orderBy } = this.state;
        const { classes, data=[
                {name: 'Page A', uv: 4000, pv: 2400, amt: 2400,},
                {name: 'Page B', uv: 3000, pv: 1398, amt: 2210,},
                {name: 'Page C', uv: 2000, pv: 9800, amt: 2290,},
                {name: 'Page D', uv: 2780, pv: 3908, amt: 2000,},
                {name: 'Page E', uv: 1890, pv: 4800, amt: 2181,},
                {name: 'Page F', uv: 2390, pv: 3800, amt: 2500,},
                {name: 'Page G', uv: 3490, pv: 4300, amt: 2100,},
              ], fieldconf} = this.props; // wellTimes, 
        
        return (
            <div>
                <div>
                    {Object.keys(data[0]).map((ts_data, row) => (
                        <Button
                            className={ !this.state[ts_data] ? classes.ts_hidden : classes.ts_shown }
                            style={{"float":"left", "padding":"0px", "min-width":"5px"}}
                            onClick={() => this.toggleColumns(ts_data)}
                        > {ts_data} 
                            <MaterialIcon
                                icon="check"
                                color="blue"
                            />
                        </Button>
                    ))}
                </div>
                <TimeSeriesEdit
                    open={this.state.open}
                    onClose={this.handleClose.bind(this)}
                    row={this.state.selectedValues}
                    fieldconf={fieldconf}
                    wellTimes={data}
                    onSubmit={this.onSubmit.bind(this)}                    
                />
                <Table style={{ "width":"100%", "margin":"0 auto" }}>
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={this.handleRequestSort}
                        columnData={fieldconf}
                    />
                    <TableBody>
                        {data.map((row, index) => (
                            <TableRow>
                                {Object.keys(row).map(ts_data => (
                                    <EnhancedTableRow
                                        ts_data={ts_data}
                                        row={row}
                                        classes={classes}
                                        editRow={this.editRow.bind(this)}
                                        fieldconf={fieldconf}
                                    />
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        );
    }
};

TimeSeriesTable = connect(
    (state, ownProps) => ({
        navState: state.nav,
        authState: state.auth,
        //wellTimes: getWellTimes(state, ownProps),
    }),
    {
        ...WellTime.actions,
        ...navActions,
    }
)(TimeSeriesTable)
export default withStyles(styles)(TimeSeriesTable);
export const NAV_TOGGLE_NAVIGATION = "NAV_TOGGLE_NAVIGATION";
export const NAV_TOGGLE_WELL_LIBRARY = "NAV_TOGGLE_WELL_LIBRARY";
export const NAV_TOGGLE_SUPPORT = "NAV_TOGGLE_SUPPORT";
export const NAV_TOGGLE_PROJECT = "NAV_TOGGLE_PROJECT";
export const NAV_SELECT_PAGE = "NAV_SELECT_PAGE";
export const WELL_SELECTED = "WELL_SELECTED";
export const WELLTIME_SELECTED = "WELLTIME_SELECTED";
export const FILTER_WELL = "FILTER_WELL";
export const DASHBOARD_SELECTOR = "DASHBOARD_SELECTOR";
export const SW_CHECKING = "SW_CHECKING";
export const SW_REGISTERED = "SW_REGISTERED";
export const SW_INSTALLED = "SW_INSTALLED";
export const SW_UPDATE = "SW_UPDATE";
export const SW_ERROR = "SW_ERROR";
export const SW_DISABLED = "SW_DISABLED";
export const STORE_READY = "STORE_READY";

export function navToggleNavigation() {
    return {
        type: NAV_TOGGLE_NAVIGATION
    };
}

export function navToggleWellLibrary() {
    return {
        type: NAV_TOGGLE_WELL_LIBRARY
    };
}
export function well_selected(well_id, id) {
    return {
        type: WELL_SELECTED,
        well_id: well_id,
        id: id
    };
}
export function welltime_selected(welltime_id) {
    return {
        type: WELLTIME_SELECTED,
        welltime_id: welltime_id,
    };
}
export function filterWell() {
    return {
        type: FILTER_WELL,
    };
}

export function navToggleSupport() {
    return {
        type: NAV_TOGGLE_SUPPORT
    };
}

export function navToggleProject() {
    return {
        type: NAV_TOGGLE_PROJECT
    };
}


export function dashToggleMapTable(button) {
    return {
        type: DASHBOARD_SELECTOR,
        button: button
    };
}

export function navSelectPage(page) {
    return {
        type: NAV_SELECT_PAGE,
        page: page
    };
}

export function swChecking() {
    return { type: SW_CHECKING };
}

export function swRegistered() {
    return { type: SW_REGISTERED };
}

export function swInstalled() {
    return { type: SW_INSTALLED };
}

export function swUpdate() {
    return { type: SW_UPDATE };
}

export function swError(error) {
    return { type: SW_ERROR, error: error };
}

export function swDisabled(reason) {
    return { type: SW_DISABLED, reason: reason };
}

export function storeReady() {
    return { type: STORE_READY };
}
